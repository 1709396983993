import React, { useEffect, useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Card, CardContent, CardMedia, Grid } from "@mui/material";
import { useNavigate, redirect } from "react-router-dom";

import { useSearchParams } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

const products = [
  {
    id: 1,
    name: "Produto 1",
    image: "https://picsum.photos/200/300",
    price: 9.99,
  },
  {
    id: 2,
    name: "Produto 2",
    image: "https://picsum.photos/200/300",
    price: 14.99,
  },
  {
    id: 3,
    name: "Produto 3",
    image: "https://picsum.photos/200/300",
    price: 19.99,
  },
  {
    id: 1,
    name: "Produto 1",
    image: "https://picsum.photos/200/300",
    price: 9.99,
  },
  {
    id: 2,
    name: "Produto 2",
    image: "https://picsum.photos/200/300",
    price: 14.99,
  },
  {
    id: 3,
    name: "Produto 3",
    image: "https://picsum.photos/200/300",
    price: 19.99,
  },
  {
    id: 1,
    name: "Produto 1",
    image: "https://picsum.photos/200/300",
    price: 9.99,
  },
  {
    id: 2,
    name: "Produto 2",
    image: "https://picsum.photos/200/300",
    price: 14.99,
  },
  {
    id: 3,
    name: "Produto 3",
    image: "https://picsum.photos/200/300",
    price: 19.99,
  },
];

const ProductList = () => {
  const navigate = useNavigate();

  const [productList, setProductList] = useState([]);

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = async () => {
    const prices = [];
    await fetch(`${process.env.REACT_APP_API_URL}/prices`)
      .then((response) => response.json())
      .then((response) => {
        response.map((item) => {
          prices.push({
            id: item.id,
            description: item.description,
            name: item.name,
            image: item?.images && item.images[0],
            price: item.metadata.unit_amount,
            stripeAccount:  item?.metadata?.stripeAccount,
          });
        });
        setProductList(prices);
      });
  };
  const goToDetails = (id, stripeAccount) => {
    navigate(`/product-details/${stripeAccount}/` + id);
  };

  return (
    <Grid container spacing={2}>
      {productList.map((product) => (
        <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
          <Card
            onClick={() =>
              goToDetails(product.id, product.stripeAccount)
            }
          >
            <CardMedia
              component="img"
              sx={{ width: "200px", height: "200px", objectFit: "contain", marginLeft:'auto', marginRight:'auto' }}
              height="200"
              image={product.image}
              alt={product.name}
            />
            <CardContent>
              <Typography variant="h6" component="div">
                {product.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {
                  <CurrencyFormat
                    value={product?.price / 100}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$ "
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                }
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
function Home() {
  return (
    <>
      <Container
        maxWidth="sm"
        display="flex"
        paddingTop="20px"
        justifyContent="center"
      >
        <Typography variant="h4" color="inherit" component="div">
          Encontre aqui os produtos necessários para o seu condominio.
        </Typography>
        <ProductList></ProductList>
      </Container>
    </>
  );
}

export default Home;
