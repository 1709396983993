import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@mui/material';

function ComboBox( {onChange}) {
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleInputChange = (event, value) => {
    setSelectedOption(value);
    onChange( {target: { name:'category', value }})
  };

  return (
    <Autocomplete
      name="category"
      options={['LIMPEZA', 'HIGIENE', 'OUTROS']}
      value={selectedOption}
      onChange={handleInputChange}
      renderInput={(params) => (
        <TextField {...params} label="CATEGORIA" variant="outlined" />
      )}
    />
  );
}

export default ComboBox;
