import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // Cor primária preta
    },
    secondary:{
      main: '#555555'
    },
    blue:{
      main: '#006D77'
    }
  },
});

export default theme;