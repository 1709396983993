import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Modal,
  Container,
} from "@mui/material";

import "./details.css";

import { useNavigate, redirect, useParams } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProductDetails = () => {
  const { id, stripeAccount } = useParams();

  const [count, setCount] = useState(1);

  const [open, setOpen] = useState(false);

  const [product, setProduct] = useState({});

  const getProduct = async () => {
    const prices = [];
    await fetch(
      `${process.env.REACT_APP_API_URL}/price/?id=${id}&stripeAccount=${stripeAccount}`
    )
      .then((response) => response.json())
      .then((response) => {
        const product = {
          id: response?.id,
          name: response?.name,
          image: response?.images && response.images[0],
          price: response?.metadata?.unit_amount / 100,
          stock: response?.description,
          stripeId: response?.metadata.stripeAccount,
          productId: response?.id,
          defaultPrice: response?.default_price,
          recurringPrice: response?.metadata.price_recurring_id,
        };

        setProduct(product);

        const shop = localStorage.getItem("shop");

        if (shop) {
          const arrayShop = JSON.parse(shop);

          arrayShop?.map((item) => {
            console.log("óii", item);
            if (item.id === product.id) {
              setCount(item.count);
              setProduct({ ...product, count: item.count });
            }
          });
        }
      });
  };

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleAddToCart = () => {
    // Lógica para adicionar o produto ao carrinho
    console.log(
      `Adicionando ${quantity} unidades de ${product.name} ao carrinho.`
    );
    save();

    setOpen(true);
  };

  const navigate = useNavigate();

  const save = () => {
    const shop = localStorage.getItem("shop");

    if (shop) {
      const arrayShop = JSON.parse(shop);

      const exist = arrayShop.find((item) => item.id === product.id);

      if (exist) {
        const updatedArray = arrayShop.filter((item) => {
          if (item.id === product.id) item.count = count;
          return item;
        });
        localStorage.setItem("shop", JSON.stringify(updatedArray));
      } else {
        localStorage.setItem(
          "shop",
          JSON.stringify([...arrayShop, { ...product, count }])
        );
      }
    } else {
      localStorage.setItem("shop", JSON.stringify([{ ...product, count }]));
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <Container>
      <Card sx={{ display: "flex", flexDirection: "colunm" }} className="card">
        <CardMedia
          component="img"
          sx={{
            width: "200px",
            height: "200px",
            objectFit: "contain",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          image={product.image}
          alt={product.name}
        />
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography variant="h5" component="div">
              {product.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {
                <CurrencyFormat
                  value={product?.price}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              }
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Descrição: {product.stock}
            </Typography>
          </CardContent>
          <Box sx={{ p: 2 }}>
            <Typography variant="body2" color="text.secondary" marginY={1}>
              Quantidade:
            </Typography>
            <FormControl fullWidth>
              <Box display="flex">
                <Button
                  variant="contained"
                  onClick={decrement}
                  disabled={count === 0}
                >
                  -
                </Button>
                <Typography variant="h4" gutterBottom marginX={2}>
                  {count}
                </Typography>
                <Button variant="contained" onClick={increment}>
                  +
                </Button>
              </Box>
            </FormControl>
            <Typography variant="body2" color="text.secondary" marginTop={3}>
              Total:{" "}
              {
                <CurrencyFormat
                  value={product.price * count}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              }
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleAddToCart}
            >
              Adicionar ao Carrinho
            </Button>
          </Box>
        </Box>
      </Card>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Produto adicionado ao carrinho com sucesso!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2, width: "100%" }}
              onClick={() => navigate("/home")}
            >
              Voltar a comprar
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2, width: "100%" }}
              onClick={() => navigate("/checkout")}
            >
              Finalizar compras
            </Button>
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default ProductDetails;
