import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Container,
  Card,
  CardContent,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import theme from '../../Theme';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: `calc(100vh - ${theme.spacing(32)}px)`,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    maxWidth: 400,
    margin: 'auto',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: '#000000',
    '&:hover': {
      backgroundColor: '#000000',
    },
  },
  appBar: {
    backgroundColor: '#000000',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
  footer: {
    backgroundColor: '#000000',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  useEffect(()=>{
    localStorage.removeItem("user")
  },[])

  return (
    <div>
      <div className={classes.root}>
        <Container maxWidth="md">
          <Grid container spacing={4} justify="center">
            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h5" component="h2">
                    Fornecedor
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    Faça o login como fornecedor para gerenciar seus produtos.
                  </Typography>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/login-vendas')}
                  >
                    Login do Fornecedor
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h5" component="h2">
                    Condomínio
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    Faça o login para comprar produtos para o condomínio.
                  </Typography>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/login')}
                  >
                    Login Condomínio
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>

      <footer className={classes.footer}>
        <Container maxWidth="md">
          <Typography variant="body2" align="center">
            CondoShop - Sua Loja Virtual para Itens de Condomínio
          </Typography>
        </Container>
      </footer>
    </div>
  );
};

export default LandingPage;
