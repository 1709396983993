import logo from "./logo.svg";
import "./App.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import SignInScreen from "./pages/Login/Login";
import { ThemeProvider } from "@emotion/react";
import theme from "./Theme";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import React from "react";
import { fakeAuthProvider } from "./auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { UserProvider, useUser } from "./context/Context";

import { BrowserRouter } from "react-router-dom";
import Home from "./pages/Home.js/Home";
import ProductDetails from "./pages/Details/Details";
import CheckStatus from "./pages/CheckStatus/CheckStatus";
import { AppBar, Toolbar, Typography } from "@mui/material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Checkout from "./pages/Checkout/Checkout";
import CheckSessionCheckout from "./pages/CheckSession/CheckSessionCheckout";
import UserProfileAvatar from "./Components/UserProfileAvatar";
import LoginCustomer from "./pages/LoginCustomer/LoginCustomer";
import LandingPage from "./pages/LandingPage/LandingPageSite";
import CheckStatusCustomer from "./pages/CheckStatusCustomer/CheckStatusCustomer";
import AddProductPage from "./pages/MyProducts/MyProducts";
import MySales from "./pages/MySales/MySales";
import Presentation from "./pages/Presentation/Presentation";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import SearchProducts from "./pages/SearchProducts/SearchProducts";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <BrowserRouter>
          {/* <AppBar position="static">
      <Toolbar variant="dense">
          <ShoppingBasketIcon style={{marginRight:20}}/>
          <Typography variant="h6" color="inherit" component="div" style={{marginRight:'auto'}}>
          CondoShop
          </Typography>
          <UserProfileAvatar style={{margingLeft:'auto'}}></UserProfileAvatar>
      </Toolbar>
      
      </AppBar> */}
          <Routes>
            <Route path="/landing-page" element={<LandingPage></LandingPage>} />
            <Route path="/login-vendas" element={<SignInScreen />} />
            <Route path="/vendas" element={<Presentation />} />
            <Route path="/Home" element={<Presentation></Presentation>} />
            <Route path="/produtos/:id/:teste" element={<SearchProducts />} />
            <Route path="/" element={<Presentation></Presentation>} />
            <Route path="/login" element={<LoginCustomer />} />
            <Route
              path="/product-details/:stripeAccount/:id"
              element={<ProductDetails />}
            />

            <Route path="/my-products" element={<AddProductPage />} />
            <Route path="/my-sales" element={<MySales />} />

            <Route
              path="/protected"
              exact
              element={
                <RequireAuth>
                  <ProtectedPage />
                </RequireAuth>
              }
            />

            <Route
              path="/checkStatus"
              exact
              element={
                <RequireAuth>
                  <CheckStatus />
                </RequireAuth>
              }
            />
            <Route
              path="/CheckStatusCustomer"
              exact
              element={
                <RequireAuth>
                  <CheckStatusCustomer />
                </RequireAuth>
              }
            />
            <Route
              path="/CheckSessionCheckout"
              exact
              element={
                <RequireAuth>
                  <CheckSessionCheckout />
                </RequireAuth>
              }
            />
            <Route
              path="/checkout"
              exact
              element={
                <RequireAuth>
                  <CheckoutPage />
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </UserProvider>
    </ThemeProvider>
  );
}

let AuthContext = React.createContext(null);

function AuthProvider({ children }) {
  let [user, setUser] = React.useState(null);

  let signin = (newUser, callback) => {
    return fakeAuthProvider.signin(() => {
      setUser(newUser);
      callback();
    });
  };

  let signout = (callback) => {
    return fakeAuthProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

function AuthStatus() {
  let auth = useAuth();
  let navigate = useNavigate();

  if (auth && !auth.user) {
    return <p>You are not logged in.</p>;
  }

  return (
    <p>
      Welcome {auth.user}!{" "}
      <button
        onClick={() => {
          auth.signout(() => navigate("/"));
        }}
      >
        Sign out
      </button>
    </p>
  );
}

function RequireAuth({ children }) {
  const { user } = useUser();
  let location = useLocation();

  console.log(firebase.auth()?.currentUser?.email);

  // if (!user) {
  //   // Redirect them to the /login page, but save the current location they were
  //   // trying to go to when they were redirected. This allows us to send them
  //   // along to that page after they login, which is a nicer user experience
  //   // than dropping them off on the home page.
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }

  return children;
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}

export default App;
