import React, { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { AppBar, Box, CardMedia, Container, IconButton, Toolbar, Typography } from '@mui/material';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { useNavigate } from 'react-router-dom';



import { useUser } from '../../context/Context';

import ImageLogin from '../../assets/img/bwink_msc_07_single_09.jpg';

import { makeStyles } from '@material-ui/core/styles';

import theme from '../../Theme';
import UserProfileAvatar from '../../Components/UserProfileAvatar';

// Configure Firebase.
const firebaseConfig = {
  apiKey: "AIzaSyA5STNwnN0QiPXNT1SdCaQ97QC9B6FO6RA",
  authDomain: "condoshop-76364.firebaseapp.com",
  projectId: "condoshop-76364",
  storageBucket: "condoshop-76364.appspot.com",
  messagingSenderId: "959647217270",
  appId: "1:959647217270:web:51fcdd38854e4d5df8d157",
  measurementId: "G-LYGJMC9KN0"
};

firebase.initializeApp(firebaseConfig);



const useStyles = makeStyles(() => ({
  root: {
    minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    paddingBottom: theme.spacing(8),
  },

  footer: {
    backgroundColor: '#000000',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));

function SignInScreen() {

  const [productList, setProductList] = useState([]);

  const [listen, setListen] = useState(1);

  const [arrayShop, setArrayShop] = React.useState([]);

  const classes = useStyles();


  React.useEffect(() => {
    const shop = localStorage.getItem("shop");

    if (shop) {
      const arrayShop = JSON.parse(shop || []);

      setArrayShop(arrayShop);
    }
  }, [listen]);

  const totalValue = () => {
    const total = arrayShop.reduce((acumulador, objeto) => {
      return acumulador + objeto.price * objeto.count;
    }, 0);

    return total;
  };

  const handleDelete = (id) => {
    // Lógica para deletar o item
    const novoArray = arrayShop.filter((produto) => produto.id !== id);

    console.log(novoArray);
    setArrayShop(novoArray);
    localStorage.setItem("shop", JSON.stringify(novoArray));
    setListen(listen + 1);
  };

  const navigate = useNavigate();
  const [widget, setWidget] = useState(null);
  const { login, user } = useUser();

  useEffect(() => {
    localStorage.removeItem("user");
  }, []);

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        fullLabel: "Entrar com Email",
      },
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: "Entrar com o Google"
      },
      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        fullLabel: "Entrar com o Facebook"
      }
    ],
    theme: {
      // Define a cor primária preta
      palette: {
        primary: {
          main: '#000000', // Cor preta
        },
      },
    },
    callbacks: {
      signInSuccessWithAuthResult: async (authResult) => {
        login(firebase.auth().currentUser);
        navigate('/CheckStatusCustomer');
      },
    },
  };

  useEffect(() => {
    setWidget(<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />);
  }, []);

  return (
    <>
    <div className={classes.root}>
            {/*  HEADER */}
            <header>
        {/*  TOP HEADER */}
        {/*  /TOP HEADER */}

        {/*  MAIN HEADER */}
        <div id="header">
          {/*  container */}
          <div class="container">
            {/*  row */}
            <div class="row header-page" style={{ display: "flex", alignItems: "center" }}>
              {/*  LOGO */}
              <div class="col-md-3">
                <Toolbar variant="dense" onClick={()=> navigate('/')}>
                  <ShoppingBasketIcon
                    style={{
                      marginRight: 20,
                      color: "white",
                      fontSize: "30px",
                    }}
                  />
                  <Typography
                    variant="h6"
                    color="inherit"
                    component="div"
                    style={{
                      marginRight: "auto",
                      color: "white",
                      fontSize: "30px",
                    }}
                  >
                    CondoShop
                  </Typography>
                </Toolbar>
             
              </div>
              {/*  /LOGO */}

              {/*  SEARCH BAR */}
              <div class="col-md-6">
                <div class="header-search">
                  <form>
                    {/* <select class="input-select">
                      <option value="0">Todas categorias</option>
                      <option value="1">Limpeza</option>
                      <option value="2">Higiene</option>
                    </select> */}
                    <input class="input" placeholder="Procurar aqui" />
                    <button class="search-btn">Pesquisar</button>
                  </form>
                </div>
              </div>
              {/*  /SEARCH BAR */}

              {/*  ACCOUNT */}
              <div class="col-md-3 clearfix">
                <div class="header-ctn">
                  {/*  Cart */}
                  <div class="dropdown">
                    <a
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <i class="fa fa-shopping-cart"></i>
                      <span>Seu Carrinho</span>
                      <div class="qty">{arrayShop.length}</div>
                    </a>
                    <div class="cart-dropdown">
                      <div class="cart-list">
                        {arrayShop &&
                          arrayShop.map((item) => (
                            <div class="product-widget">
                              <div class="product-img">
                                <img src={item.image} alt="" />
                              </div>
                              <div class="product-body">
                                <h3 class="product-name">
                                  <a href="#">{item.name}</a>
                                </h3>
                                <h4 class="product-price">
                                  <span class="qty">{item.count}x</span>
                                  {item.price}
                                </h4>
                              </div>
                              <button
                                class="delete"
                                onClick={() => {
                                  handleDelete(item.id);
                                }}
                              >
                                <i class="fa fa-close"></i>
                              </button>
                              <hr />
                            </div>
                          ))}
                      </div>
                      <div class="cart-summary">
                        <small>{arrayShop.length} Item(s) selecionados</small>
                        <h5>SUBTOTAL: {totalValue()}</h5>
                      </div>
                      <div class="cart-btns">
                        <a
                          href="#"
                          style={{ width: "100%" }}
                          onClick={() => navigate("/checkout")}
                        >
                          Pagamento <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/*  /Cart */}

                  {/*  Menu Toogle */}
                  <UserProfileAvatar></UserProfileAvatar>
                  {/*  /Menu Toogle */}
                </div>
              </div>
              {/*  /ACCOUNT */}
            </div>
            {/*  row */}
          </div>
          {/*  container */}
        </div>
        {/*  /MAIN HEADER */}
      </header>
      {/*  /HEADER */}

      {/*  NAVIGATION */}
      <nav id="navigation" style={{marginBottom:'30px'}}>
        {/*  container */}
        <div class="container">
          {/*  responsive-nav */}
          <div id="responsive-nav">
            <ul class="main-nav nav navbar-nav">
              <li>
                <a href="#" onClick={()=> navigate('/')}>Home</a>
              </li>
              {/* <li>
                <a href="#">Limpeza</a>
              </li>
              <li>
                <a href="#">Higiene</a>
              </li>
              <li>
                <a href="#">Acessórios</a>
              </li>
              <li>
                <a href="#">Outros</a>
              </li> */}
            </ul>
          </div>
          {/*  /responsive-nav */}
        </div>
        {/*  /container */}
      </nav>
      {/*  /NAVIGATION */}
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Entrar no CondoShop
      </Typography>
      {user?.username && <Typography variant="h6" align="center" gutterBottom>{user.username}</Typography>}
      <Typography variant="h5" align="center" gutterBottom marginBottom={'30px'}>
        Melhor plataforma para compras de itens de condomínio
      </Typography>
      {widget}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
        <CardMedia
          component="img"
          sx={{ width: "300px", height: "300px", objectFit: "contain" }}
          image={ImageLogin}
        />
      </Box>

    </Container>
       
        </div>
           <footer className={classes.footer}>
           <Container maxWidth="md">
             <Typography variant="body2" align="center">
               CondoShop - Sua Loja Virtual para Itens de Condomínio
             </Typography>
           </Container>
         </footer>
         </>
  );
}

export default SignInScreen;
