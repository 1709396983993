import { CircularProgress } from '@mui/material';
import React from 'react';
import { useNavigate,redirect } from 'react-router-dom';

const CheckStatusCustomer = () => {
    const navigate = useNavigate();
    React.useEffect( async ()=>{

        const user = JSON.parse(localStorage.getItem('user'))

        const response = await fetch(`${process.env.REACT_APP_API_URL}/finish-create-account-customer?email=${user._delegate.email}&nome=${user._delegate.displayName}&codeFirebase=${user._delegate.uid}`)
        const jsonData = await response.json();
        window.location.replace(jsonData.accountLink)
    },[])

    return (
        <div>
             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
            </div>
        </div>
    );
};

export default CheckStatusCustomer;