import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { CartItem } from "../../Components/AddItem";
import UserProfileAvatar from "../../Components/UserProfileAvatar";
import { AppBar, Toolbar } from "@mui/material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import {
  Badge,
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import CurrencyFormat from "react-currency-format";
import { clear } from "localforage";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/system";
import SaleDetailsModal from "../../Components/ModalDetailsSales";

import { useSearchParams } from "react-router-dom";

// Estilize o Badge para remover a transformação
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-root": {
    width: "100%",
  },
  "& .MuiBadge-badge": {
    transform: "none",
    color: "white",
  },
}));

const CheckSessionCheckout = () => {
  const navigate = useNavigate();

  const [arrayPedidos, setArrayPedidos] = React.useState([]);

  const [productList, setProductList] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [currentSale, setCurrentSale] = useState({});

  const [isPaymentChecked, setIsPaymentChecked] = useState(false);

  const [listen, setListen] = useState(1);

  const [arrayShop, setArrayShop] = React.useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const StyledBadgeType = styled(Box)(({ theme }) => ({
    display: "inline-block",
    padding: "1px 8px",
    backgroundColor: "black",
    color: "#fff",
    borderRadius: "4px",
    fontSize: "12px",
    position: "absolute",
    bottom: "-3px",
  }));

  useEffect(() => {
    checkStatusSession();

    const paramValue = searchParams.get("clear");
    if (paramValue) localStorage.setItem("shop", []);
  }, []);

  React.useEffect(() => {
    const shop = localStorage.getItem("shop");

    if (shop) {
      const arrayShop = JSON.parse(shop || []);

      setArrayShop(arrayShop);
    }
  }, [listen]);

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = async () => {
    const prices = [];
    await fetch(`${process.env.REACT_APP_API_URL}/prices`)
      .then((response) => response.json())
      .then((response) => {
        response.map((item) => {
          prices.push({
            id: item.id,
            description: item.description,
            name: item.name,
            image: item?.images && item.images[0],
            price: item.metadata.unit_amount,
            stripeAccount: item?.metadata?.stripeAccount,
          });
        });
        setProductList(prices);
      });
  };

  const handleDelete = (id) => {
    // Lógica para deletar o item
    const novoArray = arrayShop.filter((produto) => produto.id !== id);

    console.log(novoArray);
    setArrayShop(novoArray);
    localStorage.setItem("shop", JSON.stringify(novoArray));
    //setListen(listen+1)
  };

  const checkStatusSession = async () => {
    const user = localStorage.getItem("user");

    const userJson = JSON.parse(user);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/checkout-session/` +
          userJson._delegate.uid
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Products sent successfully", data);
        setArrayPedidos(data.pedidos);
      } else {
        console.error("Failed to send products");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleItemClick = (item) => {
    setCurrentSale(item);
    setIsOpen(true);
  };

  const handleDetails = async (item) => {
    console.log(item);

    await fetch(
      `${process.env.REACT_APP_API_URL}/gerar-link-invoice/${item.client_reference_id}/${item.invoice}`
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        window.location.href = response.link;
      });
  };

  const totalValue = (items) => {
    const total = items?.reduce((acumulador, objeto) => {
      return acumulador + objeto.price * objeto.count;
    }, 0);

    return total;
  };
  return (
    <>
      {/*  HEADER */}
      <header>
        {/*  TOP HEADER */}
        {/*  /TOP HEADER */}

        {/*  MAIN HEADER */}
        <div id="header">
          {/*  container */}
          <div class="container">
            {/*  row */}
            <div
              class="row header-page"
              style={{ display: "flex", alignItems: "center" }}
            >
              {/*  LOGO */}
              <div class="col-md-3">
                <Toolbar variant="dense" onClick={() => navigate("/")}>
                  <ShoppingBasketIcon
                    style={{
                      marginRight: 20,
                      color: "white",
                      fontSize: "30px",
                    }}
                  />
                  <Typography
                    variant="h6"
                    color="inherit"
                    component="div"
                    style={{
                      marginRight: "auto",
                      color: "white",
                      fontSize: "30px",
                    }}
                  >
                    CondoShop
                  </Typography>
                </Toolbar>
              </div>
              {/*  /LOGO */}

              {/*  SEARCH BAR */}
              <div class="col-md-6">
                <div class="header-search">
                  <form>
                    {/* <select class="input-select">
                      <option value="0">Todas categorias</option>
                      <option value="1">Limpeza</option>
                      <option value="2">Higiene</option>
                    </select> */}
                    <input class="input" placeholder="Procurar aqui" />
                    <button class="search-btn">Pesquisar</button>
                  </form>
                </div>
              </div>
              {/*  /SEARCH BAR */}

              {/*  ACCOUNT */}
              <div class="col-md-3 clearfix">
                <div class="header-ctn">
                  {/*  Cart */}
                  {/* <div class="dropdown">
                    <a
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <i class="fa fa-shopping-cart"></i>
                      <span>Seu Carrinho</span>
                      <div class="qty">{arrayShop.length}</div>
                    </a>
                    <div class="cart-dropdown">
                      <div class="cart-list">
                        {arrayShop &&
                          arrayShop.map((item) => (
                            <div class="product-widget">
                              <div class="product-img">
                                <img src={item.image} alt="" />
                              </div>
                              <div class="product-body">
                                <h3 class="product-name">
                                  <a href="#">{item.name}</a>
                                </h3>
                                <h4 class="product-price">
                                  <span class="qty">{item.count}x</span>
                                  <CurrencyFormat
                                    value={item?.price / 100}
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$ "
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                  />
                                </h4>
                              </div>
                              <button
                                class="delete"
                                onClick={() => {
                                  handleDelete(item.id);
                                }}
                              >
                                <i class="fa fa-close"></i>
                              </button>
                              <hr />
                            </div>
                          ))}
                      </div>
                      <div class="cart-summary">
                        <small>{arrayShop.length} Item(s) selecionados</small>
                        <h5>
                          SUBTOTAL:{" "}
                          {
                            <CurrencyFormat
                              value={totalValue()}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix="R$ "
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          }
                        </h5>
                      </div>
                      <div class="cart-btns">
                        <a
                          href="#"
                          style={{ width: "100%" }}
                          onClick={() => navigate("/checkout")}
                        >
                          Pagamento <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/*  /Cart */}

                  {/*  Menu Toogle */}
                  <UserProfileAvatar
                    style={{ margingLeft: "auto" }}
                  ></UserProfileAvatar>
                  {/*  /Menu Toogle */}
                </div>
              </div>
              {/*  /ACCOUNT */}
            </div>
            {/*  row */}
          </div>
          {/*  container */}
        </div>
        {/*  /MAIN HEADER */}
      </header>
      {/*  /HEADER */}
      <div class="container">
        <SaleDetailsModal
          isOpen={isOpen}
          sales={currentSale}
          onClose={() => {
            setIsOpen(false);
          }}
          status={false}
          details={(e) => handleDetails(e)}
        />
        <h3 class="title" style={{ marginTop: "140px" }}>
          MEUS PEDIDOS
        </h3>
        {arrayPedidos?.length <= 0 ? (
          <>
            <Box
              display={"flex"}
              flexDirection="column"
              alignItems={"center"}
              justifyContent="center"
            >
              {" "}
              <Typography
                style={{
                  whiteSpace: "pre-wrap",
                  marginTop: "50px",
                  textAlign: "center",
                }}
                variant="subtitle1"
              >
                Nenhum pedido encontrado...
              </Typography>{" "}
            </Box>
            <Box
              onClick={() => navigate("/home")}
              display={"flex"}
              flexDirection="column"
              alignItems={"center"}
              justifyContent="center"
              marginTop={"50px"}
            >
              {/* Conteúdo do componente */}
              {/* Ícone de vazio com ação de adicionar */}
              <Typography
                style={{
                  whiteSpace: "pre-wrap",
                  marginTop: "50px",
                  textAlign: "center",
                }}
                variant="subtitle2"
              >
                Faça o seu primeiro aqui
              </Typography>{" "}
              <AddCircleOutlineIcon />
            </Box>
          </>
        ) : (
          <>
            <>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  paddingBottom: "120px",
                }}
              >
                {arrayPedidos?.map((item) => (
                  <React.Fragment key={item.id}>
                    <StyledBadge
                      color={`${
                        item.payment_status == "paid" ? "success" : "warning"
                      }`}
                      style={{ transform: "none", width: "100%" }}
                      badgeContent={`${
                        item.payment_status == "paid"
                          ? "Pago"
                          : "Pagamento pendente"
                      }`}
                    >
                      <ListItem
                        style={{
                          position: "relative",
                          marginBottom: "15px",
                          paddingRight: "70px",
                        }}
                        onClick={() => handleItemClick(item)}
                      >
                        {item.mode === "subscription" && (
                          <StyledBadgeType>Recorrente</StyledBadgeType>
                        )}
                        <Box display="flex" flexDirection="column">
                          <Box style={{ paddingTop: "20px" }}>
                            {item.items.map((pedido) => (
                              <Box
                                display="flex"
                                alignItems="center"
                                marginTop="-20px"
                                key={pedido.id}
                              >
                                <CardMedia
                                  image={pedido.image}
                                  component="img"
                                  sx={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "cover",
                                  }}
                                />
                                <Typography
                                  onClick={() => handleItemClick(item)}
                                  style={{
                                    marginLeft: "10px",
                                  }}
                                  fontWeight="bold"
                                  fontSize="0.750rem"
                                  variant="body2"
                                >
                                  {`qtd:${pedido.count}x`}
                                </Typography>
                                <Typography
                                  onClick={() => handleItemClick(item)}
                                  style={{
                                    marginLeft: "10px",
                                  }}
                                  variant="body2"
                                >
                                  {pedido.name}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>

                        <Typography
                          style={{
                            marginLeft: "auto",
                            position: "absolute",
                            right: "0",
                            bottom: "-10px",
                          }}
                        >
                          <CurrencyFormat
                            value={item.totalValue / 100}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$ "
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </Typography>
                      </ListItem>
                    </StyledBadge>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </>
          </>
        )}
      </div>
    </>
  );
};

export default CheckSessionCheckout;
