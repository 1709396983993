import React from "react";

import Logo from "../assets/img/logo.png";
import Icon from "../assets/img/icon.png";

const LogoGrafica = () => {
  return (
    <div
      style={{
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      }}
    >
      <img src={Logo} width="80%" />
      <img className="icon-grafica" src={Icon} width="80%" />
    </div>
  );
};

export default LogoGrafica;
