import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Badge,
  IconButton,
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CurrencyFormat from 'react-currency-format';

import { AppBar, Toolbar } from "@mui/material";

import { styled } from '@mui/system';
import SaleDetailsModal from '../../Components/ModalDetailsSales';

import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import UserProfileAvatar from "../../Components/UserProfileAvatar";


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-root': {
    width: '100%',
  },
  '& .MuiBadge-badge': {
    transform: 'none',
    color:'white'
  },
}));
const StyledBadgeType = styled(Box)(({ theme }) => ({
    display: 'inline-block',
    padding: '1px 8px',
    backgroundColor: 'black',
    color: '#fff',
    borderRadius: '4px',
    fontSize: '12px',
    position: 'absolute',
    bottom: '-3px'

}));

const CheckSessionCheckout = () => {
  const history = useNavigate();
  const [arrayPedidos, setArrayPedidos] = useState([]);

  const [currentSale, setCurrentSale ] = useState({});

  const [isOpen, setIsOpen ] = useState(false);


  useEffect(() => {
    checkStatusSession();
  }, []);

  const checkStatusSession = async () => {
    const user = localStorage.getItem('user');
    const userJson = JSON.parse(user);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/my-sales/${userJson._delegate.uid}`);

      if (response.ok) {
        const data = await response.json();
        console.log('Products sent successfully', data);
        setArrayPedidos(data.pedidos);
      } else {
        console.error('Failed to send products');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleItemClick = (item) => {
    setCurrentSale(item)
    setIsOpen(true)

  };

  return (
    <>      {/*  HEADER */}
    <header>
      {/*  TOP HEADER */}
      {/*  /TOP HEADER */}

      {/*  MAIN HEADER */}
      <div id="header">
        {/*  container */}
        <div class="container">
          {/*  row */}
          <div class="row header-page" style={{ display: "flex", alignItems: "center" }}>
            {/*  LOGO */}
            <Toolbar variant="dense" style={{ marginRight: "auto" }}>
              <ShoppingBasketIcon
                style={{
                  marginRight: 20,
                  color: "white",
                  fontSize: "30px",
                }}
              />
              <Typography
                variant="h6"
                color="inherit"
                component="div"
                style={{
                  marginRight: "auto",
                  color: "white",
                  fontSize: "30px",
                }}
              >
                CondoShop
              </Typography>
            </Toolbar>
            <UserProfileAvatar
              style={{ margingLeft: "auto" }}
            ></UserProfileAvatar>
            {/*  /LOGO */}
          </div>
          {/*  row */}
        </div>
        {/*  container */}
      </div>
      {/*  /MAIN HEADER */}
    </header>
    {/*  /HEADER */}
    <div class="container">
      <h3 style={{ marginTop: "130px" }}>MINHAS VENDAS</h3>
    <Container>
        <SaleDetailsModal isOpen={isOpen} sales={currentSale} onClose={()=>{setIsOpen(false)}} />
      {arrayPedidos?.length <= 0 ? (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              style={{
                whiteSpace: 'pre-wrap',
                marginTop: '50px',
                textAlign: 'center',
              }}
              variant="subtitle1"
            >
              Nenhum pedido encontrado...
            </Typography>
          </Box>
          <Box
            onClick={() => history.push('/home')}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            marginTop="50px"
          >
            <Typography
              style={{
                whiteSpace: 'pre-wrap',
                marginTop: '50px',
                textAlign: 'center',
              }}
              variant="subtitle2"
            >
              Faça o seu primeiro pedido aqui
            </Typography>
            <AddCircleOutlineIcon />
          </Box>
        </>
      ) : (
        <>
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              paddingBottom: '120px',
            }}
          >
            {arrayPedidos?.map((item) => (
              <React.Fragment key={item.id}>
                <StyledBadge
                  color={  item.status_send === 'ENTREGUE' ? 'primary' : item.status_send === 'EM TRÂNSITO' ? 'secondary' : 'blue' }
                  style={{ transform: 'none', width: '100%' }}
                  badgeContent={
                    item.status_send === 'ENTREGUE' ? 'Entregue' : item.status_send === 'EM TRÂNSITO' ? 'Em trânsito' : 'Preparando envio'
                  }
                >
                  <ListItem
                    style={{
                      position: 'relative',
                      marginBottom: '15px',
                      paddingRight: '70px',
                    }}
                    onClick={() => handleItemClick(item)}
                  >
                    {item.mode === 'subscription'  && <StyledBadgeType>Recorrente</StyledBadgeType>}
                    <Box
                      display="flex"
                      flexDirection="column"
                    >
                      <Box style={{ paddingTop: '20px' }}>
                        {item.items.map((pedido) => (
                          <Box
                            display="flex"
                            alignItems="center"
                            marginTop="-20px"
                            key={pedido.id}
                          >
                            <CardMedia
                              image={pedido.image}
                              component="img"
                              sx={{
                                width: '50px',
                                height: '50px',
                                objectFit: 'cover',
                              }}
                            />
                            <Typography
                              onClick={() => handleItemClick(item)}
                              style={{
                                marginLeft: '10px',
                              }}
                              fontWeight="bold"
                              fontSize="0.750rem"
                              variant="body2"
                            >
                              {`qtd:${pedido.count}x`}
                            </Typography>
                            <Typography
                              onClick={() => handleItemClick(item)}
                              style={{
                                marginLeft: '10px',
                              }}
                              variant="body2"
                            >
                              {pedido.name}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>

                    <Typography
                      style={{
                        marginLeft: 'auto',
                        position: 'absolute',
                        right: '0',
                        bottom: '-10px',
                      }}
                    >
                      <CurrencyFormat
                        value={item.totalValue/100}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="R$ "
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </Typography>
                  </ListItem>
                </StyledBadge>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </>
      )}

    </Container>
    </div>
    </>
  );
};

export default CheckSessionCheckout;
