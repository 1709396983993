import React from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useLocation, useNavigate } from "react-router-dom";

const UserProfileAvatar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const [showAvatar, setShowAvatar] = React.useState(false);
  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    const user = localStorage.getItem("user");

    if (user) {
      setShowAvatar(true);
      setUser(JSON.parse(user));
    } else {
      setShowAvatar(false);
    }
  }, [location, navigate]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (url) => {
    setAnchorEl(null);
  };

  const handleSeeFaturamento = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/dashboard/${user.stripeId}`
    );
    const jsonData = await response.json();

    window.location.href = jsonData.link;
  };

  const handleLogout = () => {
    // Lógica para fazer logout

    // Remove todos os itens do localStorage

    // Redireciona para a página de login (ou outra página desejada)
    console.log(user);
    if (user.role == "admin") {
      window.location.href = "/";
    } else {
      window.location.href = "/";
    }

    localStorage.clear();

    handleClose();
  };

  return (
    <>
      {
        <div>
          {/* <IconButton
            size="large"
            aria-label="avatar"
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Avatar>
              <AccountCircleIcon />
            </Avatar>
          </IconButton>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {!showAvatar && (
              <>
                <MenuItem
                  onClick={() => {
                    navigate("/login");
                    handleClose();
                  }}
                >
                  <ListAltIcon />
                  Login Comprador
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/login-vendas");
                    handleClose();
                  }}
                >
                  <ShoppingCartIcon />
                  Login Fornecedor
                </MenuItem>
              </>
            )}
            {showAvatar && user.role === "admin" && (
              <>
                <MenuItem
                  onClick={() => {
                    navigate("/my-products");
                    handleClose();
                  }}
                >
                  <ListAltIcon />
                  Meus Produtos
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/my-sales");
                    handleClose();
                  }}
                >
                  <ShoppingCartIcon />
                  Minhas Vendas
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleSeeFaturamento()
                    handleClose();
                  }}
                >
                  <ListAltIcon />
                  Faturamentos
                </MenuItem>
                <MenuItem onClick={() => handleLogout()}>
                <ExitToAppIcon />
                Logout
              </MenuItem>
              </>
            )
              }
             {showAvatar && user.role !== "admin" && ( 
              <>
                {" "}
                <MenuItem
                  onClick={() => {
                    navigate("/CheckSessionCheckout");
                    handleClose();
                  }}
                >
                  <ListAltIcon />
                  Meus Pedidos
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/checkout");
                    handleClose();
                  }}
                >
                  <ShoppingCartIcon />
                  Meu Carrinho
                </MenuItem>
                  <MenuItem onClick={() => handleLogout()}>
                <ExitToAppIcon />
                Logout
              </MenuItem>
              </>
            )}

           
          </Menu> */}
        </div>
      }
    </>
  );
};

export default UserProfileAvatar;
