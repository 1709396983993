import React, { useEffect, useState } from "react";

export function CartItem({ productToSave , onChange, showButton=true, color="white"  }) {

  const [count, setCount] = useState(0);

  const [open, setOpen] = useState(false);

  const [product , setProduct] = useState({})


  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
        setCount(count - 1);
    }
  };

  useEffect(()=>{
    getProduct()
  },[productToSave])

  const getProduct = async () => {
    console.log(productToSave)
    const prices = []
      
      setProduct(productToSave)

      const shop = localStorage.getItem('shop')

      if(shop){

        const arrayShop = JSON.parse(shop)

        arrayShop?.map(item=>{
          if(item.id === productToSave?.id){
            setCount(item.count)
            setProduct({...productToSave,count:item.count})
          }
        })

      }   
  }

  const save = () => {

    const shop = localStorage.getItem('shop')

   if(shop) {
    const arrayShop = JSON.parse(shop)

   const exist = arrayShop.find((item) =>  item.id === product.id)

   if(exist){

    const updatedArray = arrayShop.filter(item => {
      if(item.id === product.id) item.count = count
      return item
    })
    localStorage.setItem('shop',JSON.stringify(updatedArray))
   
   }else {
    localStorage.setItem('shop',JSON.stringify([...arrayShop,{...product, count}]))
   }

   
   } else {
    localStorage.setItem('shop',JSON.stringify([{...product, count}]))
   }
   onChange()
  
  }

  return (
    <>
    <div className="cart-item d-flex align-items-center">
      <button className="btn btn-danger me-2" onClick={handleDecrement}>
        -
      </button>
      <span
        style={{
    fontSize: "20px",
    padding: "10px",
    color,
    marginBottom: "3px"}}
      >
        {count}
      </span>
      <button className="btn btn-success ms-2" onClick={handleIncrement}>
        +
      </button>
    </div>
    {showButton && <button class="add-to-cart-btn" onClick={()=>{save()}}>
     <i class="fa fa-shopping-cart"></i> Adicionar
   </button>}
   </>
  );
}
