import { CircularProgress } from '@mui/material';
import React from 'react';
import { useNavigate,redirect } from 'react-router-dom';

const CheckStatus = () => {
    const navigate = useNavigate();
    React.useEffect( async ()=>{

        setTimeout( async()=>{

        const user = JSON.parse(localStorage.getItem('user'))

        const response = await fetch(`${process.env.REACT_APP_API_URL}/create-account?email=${user._delegate.email}&nome=${user._delegate.displayName}&codeFirebase=${user._delegate.uid}&role=admin`)
        const jsonData = await response.json();


        localStorage.setItem('user', JSON.stringify({...user,stripeId: jsonData.stripeId}))
        window.location.href = jsonData.accountLink
    },1500)
    },[])

    return (
        <div>
             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
            </div>
        </div>
    );
};

export default CheckStatus;