import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  
  const [user, setUser] = useState(localStorage.getItem('user'));

  const login = (user, role) => {
    console.log(user)
    localStorage.setItem('user', JSON.stringify({...user, role}))
    setUser(user);
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);