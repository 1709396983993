import React, { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { AppBar, Box, CardMedia, Container, IconButton, Toolbar, Typography } from '@mui/material';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { useNavigate } from 'react-router-dom';

import { useUser } from '../../context/Context';

import ImageLogin from '../../assets/img/bwink_tsp_01_single_11.jpg';

import { makeStyles } from '@material-ui/core/styles';

import theme from '../../Theme';

import UserProfileAvatar from "../../Components/UserProfileAvatar";

// Configure Firebase.
const firebaseConfig = {
  apiKey: "AIzaSyA5STNwnN0QiPXNT1SdCaQ97QC9B6FO6RA",
  authDomain: "condoshop-76364.firebaseapp.com",
  projectId: "condoshop-76364",
  storageBucket: "condoshop-76364.appspot.com",
  messagingSenderId: "959647217270",
  appId: "1:959647217270:web:51fcdd38854e4d5df8d157",
  measurementId: "G-LYGJMC9KN0"
};

firebase.initializeApp(firebaseConfig);



const useStyles = makeStyles(() => ({
  root: {
    minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    paddingTop: '130px',
  },

  footer: {
    backgroundColor: '#000000',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));

function SignInScreen() {

  const classes = useStyles();

  const navigate = useNavigate();
  const [widget, setWidget] = useState(null);
  const { login, user } = useUser();

  useEffect(() => {
    localStorage.removeItem("user");
  }, []);

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        fullLabel: "Entrar com Email",
      },
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: "Entrar com o Google"
      },
      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        fullLabel: "Entrar com o Facebook"
      }
    ],
    theme: {
      // Define a cor primária preta
      palette: {
        primary: {
          main: '#000000', // Cor preta
        },
      },
    },
    callbacks: {
      signInSuccessWithAuthResult: async (authResult) => {
        login(firebase.auth().currentUser, 'admin');
        navigate('/checkStatus');
      },
    },
  };

  useEffect(() => {
    setWidget(<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />);
  }, []);

  return (
    <>
                {/*  HEADER */}
                <header>
        {/*  TOP HEADER */}
        {/*  /TOP HEADER */}

        {/*  MAIN HEADER */}
        <div id="header">
          {/*  container */}
          <div class="container">
            {/*  row */}
            <div class="row header-page" style={{ display: "flex", alignItems: "center" }}>
              {/*  LOGO */}
              <div class="row"  style={{ marginRight:'auto' }}>
                <Toolbar variant="dense">
                  <ShoppingBasketIcon
                    style={{
                      marginRight: 20,
                      color: "white",
                      fontSize: "30px",
                    }}
                  />
                  <Typography
                    variant="h6"
                    color="inherit"
                    component="div"
                    style={{
                      marginRight: "auto",
                      color: "white",
                      fontSize: "30px",
                    }}
                  >
                    CondoShop
                  </Typography>
                 
                </Toolbar>
                
              </div>
              <UserProfileAvatar style={{marginLeft:'auto'}}></UserProfileAvatar>
              {/*  /LOGO */}

            </div>
            {/*  row */}
          </div>
          {/*  container */}
        </div>
        {/*  /MAIN HEADER */}
      </header>
      {/*  /HEADER */}

    <div className={classes.root}>
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Entrar no CondoShop como Fornecedor
      </Typography>
      {user?.username && <Typography variant="h6" align="center" gutterBottom>{user.username}</Typography>}
      <Typography variant="h5" align="center" gutterBottom marginBottom={'30px'}>
        Melhor plataforma para vendas de itens de condomínio em atacado
      </Typography>
      {widget}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
        <CardMedia
          component="img"
          sx={{ width: "300px", height: "300px", objectFit: "contain" }}
          image={ImageLogin}
        />
      </Box>

    </Container>
       
        </div>
           <footer className={classes.footer}>
           <Container maxWidth="md">
             <Typography variant="body2" align="center">
               CondoShop - Sua Loja Virtual para Itens de Condomínio
             </Typography>
           </Container>
         </footer>
         </>
  );
}

export default SignInScreen;
