import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CurrencyFormat from 'react-currency-format';
import { Navigate, useNavigate } from 'react-router-dom';

const SaleDetailsModal = ({ isOpen, onClose, sales = {}, status = true, details=()=>{} }) => {
  const [deliveryStatus, setDeliveryStatus] = useState(' ');
  const [sale, setSale] = useState({});

  const navigate = useNavigate();

  useEffect(()=>{
    setDeliveryStatus(sales.status_send)
    const saleLocal = {
        ...sales,
        deliveryAddress: sales?.deliveryAddress,
        status_send:sales?.status_send,
        paymentAmount: sales?.totalValue, // Valor fictício, você pode substituir por um valor real
        items: sales?.items?.map((item=> {
            return { id: 1, name: item.name, quantity: item.quantity, price: item.amout, image: item.image }
        }
        ))
       
      };

      setSale(saleLocal)
  },[sales,isOpen])

  const handleDeliveryStatusChange = (event) => {
    setDeliveryStatus(event.target.value)
  };

  const getDate = (time) => {
    const date = new Date(time * 1000); // Multiplica por 1000 para converter de segundos para milissegundos

    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Os meses são indexados a partir de 0, então é necessário adicionar 1
    const day = date.getDate();
    
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // Atualizar o status da entrega
    console.log('Status da entrega:', deliveryStatus);
    updateStatus()
    onClose();
  };

  const updateStatus = async ()=> {
    const user = localStorage.getItem("user");

    const userJson = JSON.parse(user);


    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/updateVendas`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stripeId: userJson.stripeId,
          status: deliveryStatus,
          session:sales
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Products sent successfully", data);
      } else {
        console.error("Failed to send products");
      }
    } catch (error) {
      console.error("Error:", error);
    }

  }


  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          borderRadius: '8px',
          p: 4,
          width: '80%',
        }}
      >
        <Typography variant="h6" component="div" align="center" mb={2}>
          Detalhes da Venda
        </Typography>
        <Typography variant="subtitle1" component="div" fontWeight="bold">
           {sales.mode === "subscription" && sales?.created && <div style={{background: "aquamarine"}}> {`Compra Recorrente -  ${getDate(sales?.created)}`}</div>} 
          </Typography>
        <Box mb={2}>
          <Typography variant="subtitle1" component="div" fontWeight="bold">
            Endereço de Entrega
          </Typography>
          <Typography variant="body1" component="div">
            {`${sale?.deliveryAddress?.street}, ${sale?.deliveryAddress?.number} - ${sale?.deliveryAddress?.complementary}`}
          </Typography>
          <Typography variant="body1" component="div">
            {`${sale?.deliveryAddress?.neighborhood}`}
          </Typography>
          <Typography variant="body1" component="div">
            {`${sale?.deliveryAddress?.city} - CEP: ${sale?.deliveryAddress?.postalCode}`}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1" component="div" fontWeight="bold">
            Status da Entrega
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="delivery-status-label">Status</InputLabel>
            <Select
              disabled={sale.status_send==='ENTREGUE' || !status}
              labelId="delivery-status-label"
              id="delivery-status-select"
              value={deliveryStatus||'PREPARANDO O ENVIO'}
              onChange={handleDeliveryStatusChange}
            >
              <MenuItem value="PREPARANDO O ENVIO">PREPARANDO O ENVIO</MenuItem>
              <MenuItem value="EM TRÂNSITO">EM TRÂNSITO</MenuItem>
              <MenuItem value="ENTREGUE">ENTREGUE</MenuItem>
            </Select>
          </FormControl>
        </Box>
     
        <Box mb={2}>
          <Typography variant="subtitle1" component="div" fontWeight="bold">
            Itens do Pedido
          </Typography>
          {sale?.items?.map((item) => (
            <Box
              key={item.id}
              display="flex"
              alignItems="center"
              mb={1}
            >
              <img src={item.image} alt={item.name} width={30} height={30} style={{ marginRight: 8 }} />
              <Typography variant="body1" component="div">
                {`${item.name} - Quantidade: ${item.quantity} - Preço:`}   <CurrencyFormat
                                  value={item?.price/100}
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$ "
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                /> cada
              </Typography>
            </Box>
          ))}
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle1" component="div" fontWeight="bold">
            Valor da compra
          </Typography>
          <Typography variant="body1" component="div">
            <CurrencyFormat
                                  value={sale?.paymentAmount/100}
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$ "
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
          </Typography>
        </Box>
        {
          !status && sale.payment_status == 'paid' && <Button variant="contained" onClick={()=>details(sale)} color="primary">
          DETALHES
        </Button>
        }

        {
          !status && sale.payment_status != 'paid' &&
          <Button variant="contained" onClick={()=>window.location.href = sale.url} color="primary">
          Pagar
        </Button>
        }
      
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} sx={{ mr: 1 }}>
            Fechar
          </Button>
          {status &&   
          <Button variant="contained" onClick={handleSubmit} color="primary">
            Salvar
          </Button>}
        </Box>
      </Box>
    </Modal>
  );
};

export default SaleDetailsModal;
