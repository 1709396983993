import React, { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useNavigate } from "react-router-dom";
import { CartItem } from "../../Components/AddItem";
import UserProfileAvatar from "../../Components/UserProfileAvatar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import LogoGrafica from "../../Components/LogoGrafica";

import Visual from "../../assets/img/visual.png";

import Impressos from "../../assets/img/impressos.png";

import Brindes from "../../assets/img/brindes.png";

import inovandoLogo from "../../assets/img/Logo Inovando_Positivo.png";
import ModalProducts from "../../Components/ModalProducts";

const Presentation = () => {
  const navigate = useNavigate();

  const [productList, setProductList] = useState([]);

  const [productListAll, setProductListAll] = useState([]);

  const [filter, setFilter] = useState("");

  const [filterText, setFilterText] = useState("");

  const [listen, setListen] = useState(1);

  const [arrayShop, setArrayShop] = React.useState([]);

  const [typeCarrousel, setTypeCarrousel] = useState();

  const offset = useRef(0);

  const logoRef = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      offset.current = window.pageYOffset;
      handleChange();
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleChange = () => {
    console.log(offset.current);
    if (offset.current >= 300) {
      logoRef.current.classList.add("esconde");
    } else {
      logoRef.current.classList.remove("esconde");
    }
  };

  const handleInputChange = (event) => {
    setFilterText(event.target.value);

    setProductList(
      productListAll.filter((item) =>
        item.name.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );

    if (event.target.value == "") {
      setProductList(productListAll);
    }
  };

  React.useEffect(() => {
    const shop = localStorage.getItem("shop");

    if (shop) {
      const arrayShop = JSON.parse(shop || []);

      setArrayShop(arrayShop);
    }
  }, [listen]);

  useEffect(() => {
    getProduct();
  }, []);

  const totalValue = () => {
    const total = arrayShop.reduce((acumulador, objeto) => {
      return acumulador + objeto.price * objeto.count;
    }, 0);

    return total;
  };

  const setFilterItens = (filter) => {
    setFilter(filter);
    if (filter == "") {
      setProductList(productListAll);
      return;
    }
    const array = productList.filter((item) => item.category == filter);
    console.log(array);
    setProductList(array);
  };

  const handleDelete = (id) => {
    // Lógica para deletar o item
    const novoArray = arrayShop.filter((produto) => produto.id !== id);

    console.log(novoArray);
    setArrayShop(novoArray);
    localStorage.setItem("shop", JSON.stringify(novoArray));
    setListen(listen + 1);
  };

  const getProduct = async () => {
    const prices = [];
    await fetch(`${process.env.REACT_APP_API_URL}/prices`)
      .then((response) => response.json())
      .then((response) => {
        response.map((response) => {
          if (filter && filter == response.unit_label) {
            prices.push({
              id: response?.id,
              name: response?.name,
              image: response?.images && response.images[0],
              price: response?.metadata?.unit_amount,
              stock: response?.description,
              stripeId: response?.metadata.stripeAccount,
              productId: response?.id,
              defaultPrice: response?.default_price,
              category: response.unit_label,
              recurringPrice: response?.metadata.price_recurring_id,
            });
          } else {
            prices.push({
              id: response?.id,
              name: response?.name,
              image: response?.images && response.images[0],
              price: response?.metadata?.unit_amount,
              stock: response?.description,
              stripeId: response?.metadata.stripeAccount,
              category: response.unit_label,
              productId: response?.id,
              defaultPrice: response?.default_price,
              recurringPrice: response?.metadata.price_recurring_id,
            });
          }
        });
        setProductList(prices);
        setProductListAll(prices);
      });
  };
  const goToDetails = (id, stripeAccount) => {
    navigate(`/product-details/${stripeAccount}/` + id);
  };

  return (
    <div>
      {/*  HEADER */}
      <ModalProducts
        type={typeCarrousel}
        onClose={() => setTypeCarrousel("")}
      />
      <header>
        {/*  TOP HEADER */}
        {/*  /TOP HEADER */}

        {/*  MAIN HEADER */}
        <div id="header">
          {/*  container */}
          <div class="container">
            {/*  row */}
            <div
              ref={logoRef}
              class="row header-page anime"
              style={{ display: "flex", alignItems: "center" }}
            >
              {/*  LOGO */}
              <div class="col-md-3">
                <LogoGrafica />
              </div>
              {/*  /LOGO */}

              {/*  SEARCH BAR */}
              <div class="col-md-6">
                <div class="header-search">
                  <form>
                    {/* <select class="input-select">
                      <option value="0">Todas categorias</option>
                      <option value="1">Limpeza</option>
                      <option value="2">Higiene</option>
                    </select> */}
                    <input
                      class="input"
                      value={filterText}
                      onChange={(e) => handleInputChange(e)}
                      placeholder="Procurar aqui"
                    />
                    <button class="search-btn">Pesquisar</button>
                  </form>
                </div>
              </div>
              {/*  /SEARCH BAR */}

              {/*  ACCOUNT */}
              {/* <div class="col-md-3 clearfix">
                <div class="header-ctn"> */}
              {/*  Cart */}
              {/* <div class="dropdown">
                    <a
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <i class="fa fa-shopping-cart"></i>
                      <span>Seu Carrinho</span>
                      <div class="qty">{arrayShop.length}</div>
                    </a>
                    <div class="cart-dropdown">
                      <div class="cart-list">
                        {arrayShop &&
                          arrayShop.map((item) => (
                            <div class="product-widget">
                              <div class="product-img">
                                <img src={item.image} alt="" />
                              </div>
                              <div class="product-body">
                                <h3 class="product-name">
                                  <a href="#">{item.name}</a>
                                </h3>
                                <h4 class="product-price">
                                  <span class="qty">{item.count}x</span>
                                  <CurrencyFormat
                                    value={item?.price / 100}
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$ "
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                  />
                                </h4>
                              </div>
                              <button
                                class="delete"
                                onClick={() => {
                                  handleDelete(item.id);
                                }}
                              >
                                <i class="fa fa-close"></i>
                              </button>
                              <hr />
                            </div>
                          ))}
                      </div>
                      <div class="cart-summary">
                        <small>{arrayShop.length} Item(s) selecionados</small>
                        <h5>
                          SUBTOTAL:{" "}
                          {
                            <CurrencyFormat
                              value={totalValue() / 100}
                              displayType="text"
                              thousandSeparator="."
                              decimalSeparator=","
                              prefix="R$ "
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          }
                        </h5>
                      </div>
                      <div class="cart-btns">
                        <a
                          href="#"
                          style={{ width: "100%" }}
                          onClick={() => navigate("/checkout")}
                        >
                          Pagamento <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div> */}
              {/*  /Cart */}

              {/*  Menu Toogle */}
              {/* <UserProfileAvatar
                    style={{ margingLeft: "auto" }}
                  ></UserProfileAvatar> */}
              {/*  /Menu Toogle */}
              {/* </div>
              </div> */}
              {/*  /ACCOUNT */}
            </div>
            {/*  row */}
          </div>
          {/*  container */}
        </div>
        {/*  /MAIN HEADER */}
      </header>
      {/*  /HEADER */}

      {/*  NAVIGATION */}
      <nav id="navigation">
        {/*  container */}
        <div class="container">
          {/*  responsive-nav */}
          <div id="responsive-nav">
            {/*  NAV */}
            <ul class="main-nav nav navbar-nav">
              <li>
                <a href="#" onClick={() => setFilterItens("")}>
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => setFilterItens("Comunicação Visual")}
                >
                  Comunicação Visual
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={() => setFilterItens("Impressos Gráficos")}
                >
                  Impressos Gráficos
                </a>
              </li>
              <li>
                <a href="#" onClick={() => setFilterItens("OUTROS")}>
                  Outros
                </a>
              </li>
            </ul>
            {/*  /NAV */}
          </div>
          {/*  /responsive-nav */}
        </div>
        {/*  /container */}
      </nav>
      {/*  /NAVIGATION */}

      {/*  SECTION */}
      {filter == "" && !filterText && (
        <div class="section">
          {/*  container */}
          <div class="container">
            {/*  row */}
            <div class="row">
              {/*  shop */}
              <div
                class="col-md-4 col-xs-6"
                onClick={() => setTypeCarrousel("BANNER")}
              >
                <div class="shop">
                  <div class="shop-img">
                    <img src={Visual} alt="" />
                  </div>
                  <div class="shop-body">
                    <h3>
                      Comunicação
                      <br />
                      Visual
                    </h3>
                    <a href="#" class="cta-btn">
                      Ver <i class="fa fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/*  /shop */}

              {/*  shop */}
              <div
                class="col-md-4 col-xs-6"
                onClick={() => setTypeCarrousel("IMPRESSAO")}
              >
                <div class="shop">
                  <div class="shop-img">
                    <img src={Impressos} alt="" />
                  </div>
                  <div class="shop-body">
                    <h3>
                      Impressos
                      <br /> Gráficos
                    </h3>
                    <a href="#" class="cta-btn">
                      Ver <i class="fa fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/*  /shop */}

              {/*  shop */}
              <div
                class="col-md-4 col-xs-6"
                onClick={() => setTypeCarrousel("BRINDES")}
              >
                <div class="shop">
                  <div class="shop-img">
                    <img src={Brindes} alt="" />
                  </div>
                  <div class="shop-body">
                    <h3>
                      Brindes
                      <br />
                      Diversos
                    </h3>
                    <a href="#" class="cta-btn">
                      Ver <i class="fa fa-arrow-circle-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/*  /shop */}
            </div>
            {/*  /row */}
          </div>
          {/*  /container */}
        </div>
      )}

      {/*  /SECTION */}

      {/*  SECTION */}
      <div class="section">
        {/*  container */}
        <div class="container">
          {/*  row */}
          <div class="row">
            {/*  section title */}
            <div class="col-md-12">
              <div class="section-title">
                <h3 class="title">Nossos Produtos</h3>
                <div class="section-nav">
                  {/* <ul class="section-tab-nav tab-nav">
                    <li class="active">
                      <a data-toggle="tab" href="#tab1">
                        Limpeza
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#tab1">
                        Higiene
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#tab1">
                        Acessórios
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#tab1">
                        Outros
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            {/*  /section title */}

            {/*  Products tab & slick */}
            <div class="col-md-12">
              <div class="row">
                <div class="products-tabs">
                  {/*  tab */}
                  <div id="tab1" class="tab-pane active">
                    <div class="products-slick" data-nav="#slick-nav-1">
                      {/*  product */}

                      {productList &&
                        productList.map((product) => (
                          <div class="product">
                            <div class="product-img">
                              <img src={product.image} alt="" />
                              <div class="product-label">
                                <span class="new">RECORRENTE</span>
                              </div>
                            </div>
                            <div class="product-body">
                              {/* <p class="product-category">Category</p> */}
                              <h3 class="product-name">
                                <a href="#">{product.name}</a>
                              </h3>
                              <h4 class="product-price">
                                {/* <CurrencyFormat
                                  value={product?.price / 100}
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$ "
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                /> */}
                                <button
                                  class="add-to-cart-btn"
                                  onClick={() =>
                                    window.open(
                                      "https://web.whatsapp.com/send?phone=+558195871041&amp;text=Olá, gostaria de saber mais sobre seus serviço!",
                                      "_blank"
                                    )
                                  }
                                >
                                  <i class="fa fa-shopping-cart"></i> Comprar
                                </button>
                              </h4>
                              <div class="product-rating">
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                              </div>
                            </div>
                          </div>
                        ))}

                      {/*  /product */}
                    </div>
                    <div id="slick-nav-1" class="products-slick-nav"></div>
                  </div>
                  {/*  /tab */}
                </div>
              </div>
            </div>
            {/*  Products tab & slick */}
          </div>
          {/*  /row */}
        </div>
        {/*  /container */}
      </div>
      {/*  /SECTION */}

      {/*  HOT DEAL SECTION */}

      {/*  /HOT DEAL SECTION */}

      {/*  SECTION */}
      <div class="section">
        {/*  container */}
        <div class="container">
          {/*  row */}
          <div class="row">
            {/*  Products tab & slick */}
            <div class="col-md-12">
              <div class="row"></div>
            </div>
            {/*  Products tab & slick */}

            {/*  Products tab & slick */}

            {/*  Products tab & slick */}
          </div>
          {/*  /row */}
        </div>
        {/*  /container */}
      </div>
      {/*  /SECTION */}

      {/*  NEWSLETTER */}
      <div id="newsletter" class="section">
        {/*  container */}
        <div class="container">
          {/*  row */}
          <div class="row">
            <div class="col-md-12">
              <div class="newsletter">
                <p>
                  Receba nossas <strong>PROMOÇÕES</strong>
                </p>
                <form>
                  <input
                    class="input"
                    type="email"
                    placeholder="insira seu email"
                  />
                  <button class="newsletter-btn">
                    <i class="fa fa-envelope"></i> Enviar
                  </button>
                </form>
                <ul class="newsletter-follow">
                  <li>
                    <a href="#">
                      <i class="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-pinterest"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*  /row */}
        </div>
        {/*  /container */}
      </div>
      {/*  /NEWSLETTER */}

      {/*  FOOTER */}
      <footer id="footer">
        {/*  top footer */}
        <div class="section">
          {/*  container */}
          <div class="container">
            {/*  row */}
            <div class="row">
              <div class="col-md-6 col-xs-6">
                <div class="footer">
                  <h3 class="footer-title">Sobre nós</h3>
                  <p>
                    A Sensation Gráfica Rápida, com mais de 10 anos de
                    experiência, é sua parceira ideal para impressão de lonas e
                    banners para pequenas empresas. Oferecemos qualidade e
                    agilidade para que sua mensagem se destaque no mercado.
                    Conte conosco para transformar sua visão em materiais
                    visuais impressionantes e acessíveis.
                  </p>
                  <ul class="footer-links">
                    <li>
                      <a href="https://www.google.com/maps/place/Sensation+Gr%C3%A1fica+R%C3%A1pida/@-8.015932,-34.8849815,15z/data=!4m2!3m1!1s0x0:0x1ee0d0c0c2b97fe?sa=X&ved=2ahUKEwjr9sO24dyBAxWdq5UCHacFB5EQ_BJ6BAhIEAA&ved=2ahUKEwjr9sO24dyBAxWdq5UCHacFB5EQ_BJ6BAhcEAg">
                        <i class="fa fa-map-marker"></i>R. Cel. Urbano Ribeiro
                        de Sena, 683 - Cajueiro, Recife - PE, 52221-085
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-phone"></i>+5581995871041
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-envelope-o"></i>
                        sensationgrafica@gmail.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-3 col-xs-6">
                <div class="footer">
                  <h3 class="footer-title">Catgorias</h3>
                  <ul class="footer-links">
                    <li>
                      <a href="#">Impressos Gráficos</a>
                    </li>
                    <li>
                      <a href="#">Comunicação Visual</a>
                    </li>
                    <li>
                      <a href="#">Brindes Diversos</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="clearfix visible-xs"></div>

              <div class="col-md-3 col-xs-6">
                <div class="footer">
                  <h3 class="footer-title">Serviçoes</h3>
                  <ul class="footer-links">
                    <li>
                      <a href="#">Ver carrinho</a>
                    </li>
                    <li>
                      <a href="#">Meus pedidos</a>
                    </li>
                    <li>
                      <a href="#">Ajuda</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  /row */}
          </div>
          {/*  /container */}
        </div>
        {/*  /top footer */}

        {/*  bottom footer */}

        {/*  /bottom footer */}
      </footer>
      <a href="https://www.inovandotecnologia.com.br">
        <section class="inovando">
          <small style={{ "margin-right": "7px" }}>
            Desenvolvido e mantido pela
          </small>
          <img
            style={{ width: "85px", height: "20px" }}
            src={inovandoLogo}
            alt="image"
          />
        </section>
      </a>
    </div>
  );
};

export default Presentation;
