import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useNavigate } from "react-router-dom";
import { CartItem } from "../../Components/AddItem";
import UserProfileAvatar from "../../Components/UserProfileAvatar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";

const CARRINHOPage = () => {
  const navigate = useNavigate();

  const [productList, setProductList] = useState([]);

  const [isPaymentChecked, setIsPaymentChecked] = useState(false);

  const [listen, setListen] = useState(1);

  const [arrayShop, setArrayShop] = React.useState([]);

  const [address, setAddress] = useState({
    street: "",
    neighborhood: "",
    city: "",
    postalCode: "",
    number: "",
    complementary: "",
  });

  const getUser = async () => {
    const user = localStorage.getItem("user");

    const userJson = JSON.parse(user);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/get-user?codeFirebase=${userJson._delegate.uid}`
    );
    const jsonData = await response.json();

    console.log(jsonData.deliveryAddress);

    if (jsonData.deliveryAddress) setAddress(jsonData.deliveryAddress);
  };

  const finish = async () => {
    const deliveryAddress = address;

    const recurring = isPaymentChecked;
    const user = localStorage.getItem("user");

    const userJson = JSON.parse(user);

    console.log(arrayShop);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/checkout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firebaseId: userJson._delegate.uid,
            prices: arrayShop,
            totalValue: totalValue(),
            mode: recurring ? "subscription" : "payment",
            stripeId: arrayShop[0].stripeId,
            deliveryAddress,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        window.location.href = data.url;
        console.log("Products sent successfully", data);
      } else {
        console.error("Failed to send products");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const clear = () => {
    const shop = localStorage.setItem("shop", []);
    setArrayShop([]);
  };

  useEffect(() => {
    getUser();
  }, []);

  React.useEffect(() => {
    const shop = localStorage.getItem("shop");

    if (shop) {
      const arrayShop = JSON.parse(shop || []);

      setArrayShop(arrayShop);
    }
  }, [listen]);

  useEffect(() => {
    getProduct();
  }, []);

  const totalValue = () => {
    const total = arrayShop.reduce((acumulador, objeto) => {
      return acumulador + objeto.price * objeto.count;
    }, 0);

    return total;
  };

  const handleDelete = (id) => {
    // Lógica para deletar o item
    const novoArray = arrayShop.filter((produto) => produto.id !== id);

    console.log(novoArray);
    setArrayShop(novoArray);
    localStorage.setItem("shop", JSON.stringify(novoArray));
    //setListen(listen+1)
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Lógica para lidar com o envio do formulário
    console.log("Formulário enviado!");
  };

  const handlePaymentChange = () => {
    setIsPaymentChecked(!isPaymentChecked);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const getProduct = async () => {
    const prices = [];
    await fetch(`${process.env.REACT_APP_API_URL}/prices`)
      .then((response) => response.json())
      .then((response) => {
        response.map((item) => {
          prices.push({
            id: item.id,
            description: item.description,
            name: item.name,
            image: item?.images && item.images[0],
            price: item.metadata.unit_amount,
            stripeAccount: item?.metadata?.stripeAccount,
          });
        });
        setProductList(prices);
      });
  };
  const goToDetails = (id, stripeAccount) => {
    navigate(`/product-details/${stripeAccount}/` + id);
  };

  return (
    <>
      {/*  HEADER */}
      <header>
        {/*  TOP HEADER */}
        {/*  /TOP HEADER */}

        {/*  MAIN HEADER */}
        <div id="header">
          {/*  container */}
          <div class="container">
            {/*  row */}
            <div
              class="row header-page"
              style={{ display: "flex", alignItems: "center" }}
            >
              {/*  LOGO */}
              <div class="col-md-3">
                <Toolbar variant="dense" onClick={() => navigate("/")}>
                  <ShoppingBasketIcon
                    style={{
                      marginRight: 20,
                      color: "white",
                      fontSize: "30px",
                    }}
                  />
                  <Typography
                    variant="h6"
                    color="inherit"
                    component="div"
                    style={{
                      marginRight: "auto",
                      color: "white",
                      fontSize: "30px",
                    }}
                  >
                    CondoShop
                  </Typography>
                </Toolbar>
              </div>
              {/*  /LOGO */}

              {/*  SEARCH BAR */}
              <div class="col-md-6">
                <div class="header-search">
                  <form>
                    {/* <select class="input-select">
                      <option value="0">Todas categorias</option>
                      <option value="1">Limpeza</option>
                      <option value="2">Higiene</option>
                    </select> */}
                    <input class="input" placeholder="Procurar aqui" />
                    <button class="search-btn">Pesquisar</button>
                  </form>
                </div>
              </div>
              {/*  /SEARCH BAR */}

              {/*  ACCOUNT */}
              <div class="col-md-3 clearfix">
                <div class="header-ctn">
                  {/*  Cart */}
                  {/* <div class="dropdown">
                    <a
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <i class="fa fa-shopping-cart"></i>
                      <span>Seu Carrinho</span>
                      <div class="qty">{arrayShop.length}</div>
                    </a>
                    <div class="cart-dropdown">
                      <div class="cart-list">
                        {arrayShop &&
                          arrayShop.map((item) => (
                            <div class="product-widget">
                              <div class="product-img">
                                <img src={item.image} alt="" />
                              </div>
                              <div class="product-body">
                                <h3 class="product-name">
                                  <a href="#">{item.name}</a>
                                </h3>
                                <h4 class="product-price">
                                  <span class="qty">{item.count}x</span>
                                  <CurrencyFormat
                                  value={item?.price / 100}
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$ "
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                                </h4>
                              </div>
                              <button
                                class="delete"
                                onClick={() => {
                                  handleDelete(item.id);
                                }}
                              >
                                <i class="fa fa-close"></i>
                              </button>
                              <hr />
                            </div>
                          ))}
                      </div>
                      <div class="cart-summary">
                        <small>{arrayShop.length} Item(s) selecionados</small>
                        <h5>SUBTOTAL: {  <CurrencyFormat
                                  value={totalValue()/ 100}
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$ "
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />}</h5>
                      </div>
                      <div class="cart-btns">
                        <a
                          href="#"
                          style={{ width: "100%" }}
                          onClick={() => navigate("/checkout")}
                        >
                          Pagamento <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/*  /Cart */}

                  {/*  Menu Toogle */}
                  <UserProfileAvatar
                    style={{ margingLeft: "auto" }}
                  ></UserProfileAvatar>
                  {/*  /Menu Toogle */}
                </div>
              </div>
              {/*  /ACCOUNT */}
            </div>
            {/*  row */}
          </div>
          {/*  container */}
        </div>
        {/*  /MAIN HEADER */}
      </header>
      {/*  /HEADER */}

      {/*  NAVIGATION */}
      <nav id="navigation">
        {/*  container */}
        <div class="container">
          {/*  responsive-nav */}
          <div id="responsive-nav">
            {/*  NAV */}
            <ul class="main-nav nav navbar-nav">
              <li>
                <a href="#" onClick={() => navigate("/home")}>
                  Home
                </a>
              </li>
            </ul>
            {/*  /NAV */}
          </div>
          {/*  /responsive-nav */}
        </div>
        {/*  /container */}
      </nav>
      {/*  /NAVIGATION */}

      {/*  BREADCRUMB*/}
      <div id="breadcrumb" class="section">
        {/*  container*/}
        <div class="container">
          {/*  row*/}
          <div class="row">
            <div class="col-md-12">
              <h3 class="breadcrumb-header">CARRINHO</h3>
              <ul class="breadcrumb-tree">
                <li>
                  <a href="#">Home</a>
                </li>
                <li class="active">CARRINHO</li>
              </ul>
            </div>
          </div>
          {/*  /row*/}
        </div>
        {/*  /container*/}
      </div>
      {/*  /BREADCRUMB*/}

      {/*  SECTION*/}
      <div class="section">
        {/*  container*/}
        <div class="container">
          {/*  row*/}
          <div class="row">
            <div class="col-md-7">
              {/*  Billing Details*/}
              <div className="section-title">
                <h3 className="title">ENDEREÇO DE ENTREGA</h3>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    className="input"
                    type="text"
                    name="street"
                    placeholder="Street"
                    value={address.street}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input"
                    type="text"
                    name="neighborhood"
                    placeholder="Neighborhood"
                    value={address.neighborhood}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input"
                    type="text"
                    name="city"
                    placeholder="City"
                    value={address.city}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input"
                    type="text"
                    name="postalCode"
                    placeholder="Postal Code"
                    value={address.postalCode}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input"
                    type="text"
                    name="number"
                    placeholder="Number"
                    value={address.number}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    className="input"
                    type="text"
                    name="complementary"
                    placeholder="Complementary"
                    value={address.complementary}
                    onChange={handleChange}
                  />
                </div>
              </form>

              {/*  /Billing Details*/}

              {/*  Order notes*/}
              <div class="order-notes">
                <textarea class="input" placeholder="Order Notes"></textarea>
              </div>
              {/*  /Order notes*/}
            </div>

            {/*  Order Details*/}
            <div class="col-md-5 order-details">
              <div class="section-title text-center">
                <h3 class="title">SUA COMPRA</h3>
              </div>
              <div class="order-summary">
                <div class="order-col">
                  <div>
                    <strong>PRODUTOS</strong>
                  </div>
                  <div>
                    <strong>TOTAL</strong>
                  </div>
                </div>
                <div class="order-products">
                  {arrayShop &&
                    arrayShop.map((item) => (
                      <div class="order-col">
                        <div>
                          {item.count}x {item.name}
                        </div>
                        <div>
                          <CurrencyFormat
                            value={(item.price * item.count) / 100}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$ "
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                          {}
                        </div>
                      </div>
                    ))}
                </div>
                <div class="order-col">
                  <div>Entrega</div>
                  <div>
                    <strong>grátis</strong>
                  </div>
                </div>
                <div class="order-col">
                  <div>
                    <strong>TOTAL</strong>
                  </div>
                  <div>
                    <strong class="order-total">
                      {
                        <CurrencyFormat
                          value={totalValue() / 100}
                          displayType="text"
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$ "
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                      }
                    </strong>
                  </div>
                </div>
              </div>
              <div class="payment-method">
                <div class="input-checkbox">
                  <input
                    type="checkbox"
                    name="payment"
                    id="payment-1"
                    checked={isPaymentChecked}
                    onChange={handlePaymentChange}
                  />
                  <label for="payment-1">
                    <span></span>
                    Compra recorrente
                  </label>
                  <div class="caption">
                    <p>
                      Seu pedido será repetido automaticamente todo mês.
                      Mantenha-se abastecido(a) com facilidade!
                    </p>
                  </div>
                </div>
              </div>
              <div class="input-checkbox">
                <input type="checkbox" id="terms" />
                <label for="terms">
                  <span></span>
                  Eu li e aceito os <a href="#">termos e condições</a>
                </label>
              </div>
              <a
                href="#"
                class="primary-btn order-submit"
                onClick={() => finish()}
              >
                Ir para pagamento
              </a>
            </div>
            {/*  /Order Details*/}
          </div>
          {/*  /row*/}
        </div>
        {/*  /container*/}
      </div>

      {/*  NEWSLETTER */}
      <div id="newsletter" class="section">
        {/*  container */}
        <div class="container">
          {/*  row */}
          <div class="row">
            <div class="col-md-12">
              <div class="newsletter">
                <p>
                  Sign Up for the <strong>NEWSLETTER</strong>
                </p>
                <form>
                  <input
                    class="input"
                    type="email"
                    placeholder="Enter Your Email"
                  />
                  <button class="newsletter-btn">
                    <i class="fa fa-envelope"></i> Subscribe
                  </button>
                </form>
                <ul class="newsletter-follow">
                  <li>
                    <a href="#">
                      <i class="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-pinterest"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*  /row */}
        </div>
        {/*  /container */}
      </div>
      {/*  /NEWSLETTER */}

      {/*  FOOTER */}
      <footer id="footer">
        {/*  top footer */}
        <div class="section">
          {/*  container */}
          <div class="container">
            {/*  row */}
            <div class="row">
              <div class="col-md-3 col-xs-6">
                <div class="footer">
                  <h3 class="footer-title">About Us</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut.
                  </p>
                  <ul class="footer-links">
                    <li>
                      <a href="#">
                        <i class="fa fa-map-marker"></i>1734 Stonecoal Road
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-phone"></i>+021-95-51-84
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-envelope-o"></i>email@email.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-3 col-xs-6">
                <div class="footer">
                  <h3 class="footer-title">Categories</h3>
                  <ul class="footer-links">
                    <li>
                      <a href="#">Hot deals</a>
                    </li>
                    <li>
                      <a href="#">Laptops</a>
                    </li>
                    <li>
                      <a href="#">Smartphones</a>
                    </li>
                    <li>
                      <a href="#">Cameras</a>
                    </li>
                    <li>
                      <a href="#">Accessories</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="clearfix visible-xs"></div>

              <div class="col-md-3 col-xs-6">
                <div class="footer">
                  <h3 class="footer-title">Informações</h3>
                  <ul class="footer-links">
                    <li>
                      <a href="#">Sobre nós</a>
                    </li>
                    <li>
                      <a href="#">Entre em contato</a>
                    </li>
                    <li>
                      <a href="#">Politica de privacidadey</a>
                    </li>
                    <li>
                      <a href="#">Compras e cancelamentos</a>
                    </li>
                    <li>
                      <a href="#">Termos e condições</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-3 col-xs-6">
                <div class="footer">
                  <h3 class="footer-title">Service</h3>
                  <ul class="footer-links">
                    <li>
                      <a href="#">My Account</a>
                    </li>
                    <li>
                      <a href="#">View Cart</a>
                    </li>
                    <li>
                      <a href="#">Wishlist</a>
                    </li>
                    <li>
                      <a href="#">Track My Order</a>
                    </li>
                    <li>
                      <a href="#">Help</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  /row */}
          </div>
          {/*  /container */}
        </div>
        {/*  /top footer */}

        {/*  bottom footer */}
        <div id="bottom-footer" class="section">
          <div class="container">
            {/*  row */}
            <div class="row">
              <div class="col-md-12 text-center">
                <ul class="footer-payments">
                  <li>
                    <a href="#">
                      <i class="fa fa-cc-visa"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-credit-card"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-cc-paypal"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-cc-mastercard"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-cc-discover"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-cc-amex"></i>
                    </a>
                  </li>
                </ul>
                <span class="copyright">
                  {/*  Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                  Copyright &copy;
                  <script>document.write(new Date().getFullYear());</script> All
                  rights reserved | This template is made with{" "}
                  <i class="fa fa-heart-o" aria-hidden="true"></i> by{" "}
                  <a href="https://colorlib.com" target="_blank">
                    Colorlib
                  </a>
                  {/*  Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                </span>
              </div>
              /
            </div>
            {/*  /row */}
          </div>
          {/*  /container */}
        </div>
        {/*  /bottom footer */}
      </footer>
    </>
  );
};

export default CARRINHOPage;
