import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Modal } from "@mui/material";

import img1 from "../assets/img/banner-1.png";
import img2 from "../assets/img/banner-2.png";
import img3 from "../assets/img/banner-3.png";
import img4 from "../assets/img/banner-4.png";
import img5 from "../assets/img/banner-5.png";

import img6 from "../assets/img/impressao-1.png";
import img7 from "../assets/img/impressao-2.png";
import img8 from "../assets/img/impressao-3.png";
import img9 from "../assets/img/impressao-4.png";
import img10 from "../assets/img/impressao-6.png";

import img11 from "../assets/img/brindes-1.png";
import img12 from "../assets/img/brindes-2.png";
import img13 from "../assets/img/brindes-3.png";
import img14 from "../assets/img/brindes-4.png";
import img15 from "../assets/img/brindes-5.png";

const banners = [
  { text: "Lonas no geral", img: img1 },
  { text: "Lonas no geral", img: img2 },
  { text: "Lonas no geral", img: img3 },
  { text: "Lonas no geral", img: img4 },
  { text: "Lonas no geral", img: img5 },
];

const impressao = [
  { text: "Lonas no geral", img: img6 },
  { text: "Lonas no geral", img: img7 },
  { text: "Lonas no geral", img: img8 },
  { text: "Lonas no geral", img: img9 },
  { text: "Lonas no geral", img: img10 },
];

const brindes = [
  { text: "Lonas no geral", img: img11 },
  { text: "Lonas no geral", img: img12 },
  { text: "Lonas no geral", img: img13 },
  { text: "Lonas no geral", img: img14 },
  { text: "Lonas no geral", img: img15 },
];

const ModalProducts = ({ type, onClose }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    console.log(type);
    if (type === "BANNER") {
      setItems(banners);
    }
    if (type === "IMPRESSAO") {
      setItems(impressao);
    }
    if (type === "BRINDES") {
      setItems(brindes);
    }
  }, [type]);

  return (
    <Modal
      open={type}
      onClose={() => onClose()}
      sx={{
        "& > .MuiBackdrop-root": {
          backdropFilter: "blur(2px)",
          backgroundColor: "#000000d9",
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "20px",
        }}
      >
        <Carousel>
          {items.map((item) => (
            <div className="content-carousel">
              <img src={item.img} className="img-carousel" />
            </div>
          ))}
        </Carousel>
      </div>
    </Modal>
  );
};

export default ModalProducts;
