import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CurrencyFormat from "react-currency-format";

import UserProfileAvatar from "../../Components/UserProfileAvatar";

import {
  AppBar,
  Box,
  CardMedia,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";

import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import ComboBox from "../../Components/Combobox";

const firebaseConfig = {
  apiKey: "AIzaSyA5STNwnN0QiPXNT1SdCaQ97QC9B6FO6RA",
  authDomain: "condoshop-76364.firebaseapp.com",
  projectId: "condoshop-76364",
  storageBucket: "condoshop-76364.appspot.com",
  messagingSenderId: "959647217270",
  appId: "1:959647217270:web:51fcdd38854e4d5df8d157",
  measurementId: "G-LYGJMC9KN0",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export function currencyFormatter(value) {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value / 100);

  return `${amount}`;
}

const AddProductPage = () => {
  const [open, setOpen] = useState(false);
  const [newProduct, setNewProduct] = useState({
    name: "",
    image: null,
    price: "",
    description: "",
    available: true,
  });
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    console.log(e);
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    console.log(name, value, type, checked);
    setNewProduct((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };

  const handleToggleAvailability = (index) => {
    const updatedProducts = [...products];
    updatedProducts[index].available = !updatedProducts[index].available;
    setProducts(updatedProducts);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setNewProduct((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const handleAddProduct = async () => {
    const user = localStorage.getItem("user");
    const userObeject = JSON.parse(user);
    if (newProduct.name.trim() !== "") {
      if (newProduct.image) {
        const imageFile = newProduct.image;
        const storageRef = storage.ref();
        const imageRef = storageRef.child(imageFile.name);

        const snapshot = await imageRef.put(imageFile);

        // Obtain the download URL of the image
        const imageUrl = await snapshot.ref.getDownloadURL();

        const product = {
          name: newProduct.name,
          image: imageUrl,
          price: newProduct.price,
          description: newProduct.description,
          available: newProduct.available,
          category: newProduct.category,
        };

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/create-product`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                product,
                accountId: userObeject.stripeId,
              }),
            }
          );

          if (response.ok) {
            const data = await response.json();
            const tempArray = [...products, product];

            tempArray.sort((a, b) => a.name.localeCompare(b.name));
            setProducts(tempArray);
            setNewProduct({
              name: "",
              image: null,
              price: "",
              description: "",
              available: true,
            });
            setOpen(false);
          } else {
            console.error("Failed to send products");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        console.log("Please select an image.");
      }
    }
  };

  const getProducts = async () => {
    try {
      const user = localStorage.getItem("user");
      const userObeject = JSON.parse(user);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/get-products?accountId=` +
          userObeject.stripeId
      );

      if (response.ok) {
        const data = await response.json();

        const arrayProducts = data.data;

        const MyProducts = arrayProducts.map((product) => {
          return {
            name: product.name,
            image: product.images[0],
            price: product.metadata.unit_amount,
            description: product.description,
            available: product.metadata.enabavailableled,
          };
        });

        MyProducts.sort((a, b) => a.name.localeCompare(b.name));

        setProducts([...products, ...MyProducts]);
      } else {
        console.error("Failed to send products");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {/*  HEADER */}
      <header>
        {/*  TOP HEADER */}
        {/*  /TOP HEADER */}

        {/*  MAIN HEADER */}
        <div id="header">
          {/*  container */}
          <div class="container">
            {/*  row */}
            <div class="row header-page" style={{ display: "flex", alignItems: "center" }}>
              {/*  LOGO */}
              <Toolbar variant="dense" style={{ marginRight: "auto" }}>
                <ShoppingBasketIcon
                  style={{
                    marginRight: 20,
                    color: "white",
                    fontSize: "30px",
                  }}
                />
                <Typography
                  variant="h6"
                  color="inherit"
                  component="div"
                  style={{
                    marginRight: "auto",
                    color: "white",
                    fontSize: "30px",
                  }}
                >
                  CondoShop
                </Typography>
              </Toolbar>
              <UserProfileAvatar
                style={{ margingLeft: "auto" }}
              ></UserProfileAvatar>
              {/*  /LOGO */}
            </div>
            {/*  row */}
          </div>
          {/*  container */}
        </div>
        {/*  /MAIN HEADER */}
      </header>
      {/*  /HEADER */}
      <div class="container">
        <h3 style={{ marginTop: "130px" }}>MEUS PRODUTOS</h3>
        <Button
          variant="outlined"
          style={{ marginBottom: "30px" }}
          onClick={handleClickOpen}
        >
          Adicionar Novo Produto +
        </Button>

        <List>
          {products.map((product, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar alt={product.name} src={product.image} />
              </ListItemAvatar>
              <ListItemText
                primary={product.name}
                secondary={
                  <React.Fragment>
                    <CurrencyFormat
                      value={product.price / 100} // Divida por 100 para converter centavos para reais
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"R$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                    <br />
                    Descrição: {product.description}
                  </React.Fragment>
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={product.available}
                    onChange={() => handleToggleAvailability(index)}
                  />
                }
                label="Disponível"
              />
            </ListItem>
          ))}
        </List>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Adicionar Novo Produto</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nome do Produto"
            name="name"
            value={newProduct.name}
            onChange={handleInputChange}
            fullWidth
          />
          <ComboBox onChange={handleInputChange} />
          <div>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              style={{ display: "none" }}
              id="image-upload"
            />
            <label htmlFor="image-upload">
              <Button variant="outlined" component="span">
                Selecionar Imagem
              </Button>
            </label>
            {newProduct.image && (
              <div>
                <p>Imagem selecionada: {newProduct.image.name}</p>
                <img
                  src={URL.createObjectURL(newProduct.image)}
                  alt="Imagem selecionada"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </div>
            )}
          </div>
          <CurrencyFormat
            format={currencyFormatter}
            customInput={TextField}
            margin="dense"
            label="Preço"
            name="price"
            value={newProduct.price}
            onValueChange={(values) => {
              const { value } = values;
              setNewProduct((prevProduct) => ({
                ...prevProduct,
                price: value,
              }));
            }}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            fixedDecimalScale={true}
            allowNegative={false}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Descrição"
            name="description"
            value={newProduct.description}
            onChange={handleInputChange}
            fullWidth
          />
          <FormControlLabel
            control={
              <Switch
                checked={newProduct.available}
                onChange={handleInputChange}
                name="available"
              />
            }
            label="Disponível para venda"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleAddProduct}>Adicionar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddProductPage;
