import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useNavigate } from "react-router-dom";
import { CartItem } from "../../Components/AddItem";
import UserProfileAvatar from "../../Components/UserProfileAvatar";
import { AppBar, Toolbar, Typography } from "@mui/material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";

const SearchProducts = () => {
  const navigate = useNavigate();

  const [productList, setProductList] = useState([]);

  const [listen, setListen] = useState(1);

  const [arrayShop, setArrayShop] = React.useState([]);

  React.useEffect(() => {
    const shop = localStorage.getItem("shop");

    if (shop) {
      const arrayShop = JSON.parse(shop || []);

      setArrayShop(arrayShop);
    }
  }, [listen]);

  useEffect(() => {
    getProduct();
  }, []);

  const totalValue = () => {
    const total = arrayShop.reduce((acumulador, objeto) => {
      return acumulador + objeto.price * objeto.count;
    }, 0);

    return total;
  };

  const handleDelete = (id) => {
    // Lógica para deletar o item
    const novoArray = arrayShop.filter((produto) => produto.id !== id);

    console.log(novoArray);
    setArrayShop(novoArray);
    localStorage.setItem("shop", JSON.stringify(novoArray));
    setListen(listen + 1);
  };

  const getProduct = async () => {
    const prices = [];
    await fetch(`${process.env.REACT_APP_API_URL}/prices`)
      .then((response) => response.json())
      .then((response) => {
        response.map((response) => {
          prices.push({
            id: response?.id,
            name: response?.name,
            image: response?.images && response.images[0],
            price: response?.metadata?.unit_amount,
            stock: response?.description,
            stripeId: response?.metadata.stripeAccount,
            productId: response?.id,
            defaultPrice: response?.default_price,
            recurringPrice: response?.metadata.price_recurring_id,
          });
        });
        setProductList(prices);
      });
  };
  const goToDetails = (id, stripeAccount) => {
    navigate(`/product-details/${stripeAccount}/` + id);
  };

  return (
    <>
      {/*  HEADER */}
      <header>
        {/*  TOP HEADER */}
        {/*  /TOP HEADER */}

        {/*  MAIN HEADER */}
        <div id="header">
          {/*  container */}
          <div class="container">
            {/*  row */}
            <div
              class="row header-page"
              style={{ display: "flex", alignItems: "center" }}
            >
              {/*  LOGO */}
              <div class="col-md-3">
                <Toolbar variant="dense">
                  <ShoppingBasketIcon
                    style={{
                      marginRight: 20,
                      color: "white",
                      fontSize: "30px",
                    }}
                  />
                  <Typography
                    variant="h6"
                    color="inherit"
                    component="div"
                    style={{
                      marginRight: "auto",
                      color: "white",
                      fontSize: "30px",
                    }}
                  >
                    CondoShop
                  </Typography>
                </Toolbar>
              </div>
              {/*  /LOGO */}

              {/*  SEARCH BAR */}
              <div class="col-md-6">
                <div class="header-search">
                  <form>
                    <select class="input-select">
                      <option value="0">Todas categorias</option>
                      <option value="1">Limpeza</option>
                      <option value="2">Higiene</option>
                    </select>
                    <input class="input" placeholder="Procurar aqui" />
                    <button class="search-btn">Pesquisar</button>
                  </form>
                </div>
              </div>
              {/*  /SEARCH BAR */}

              {/*  ACCOUNT */}
              <div class="col-md-3 clearfix">
                <div class="header-ctn">
                  {/*  Cart */}
                  {/* <div class="dropdown">
                    <a
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <i class="fa fa-shopping-cart"></i>
                      <span>Seu Carrinho</span>
                      <div class="qty">{arrayShop.length}</div>
                    </a>
                    <div class="cart-dropdown">
                      <div class="cart-list">
                        {arrayShop &&
                          arrayShop.map((item) => (
                            <div class="product-widget">
                              <div class="product-img">
                                <img src={item.image} alt="" />
                              </div>
                              <div class="product-body">
                                <h3 class="product-name">
                                  <a href="#">{item.name}</a>
                                </h3>
                                <h4 class="product-price">
                                  <span class="qty">{item.count}x</span>
                                  {item.price}
                                </h4>
                              </div>
                              <button
                                class="delete"
                                onClick={() => {
                                  handleDelete(item.id);
                                }}
                              >
                                <i class="fa fa-close"></i>
                              </button>
                              <hr />
                            </div>
                          ))}
                      </div>
                      <div class="cart-summary">
                        <small>{arrayShop.length} Item(s) selecionados</small>
                        <h5>SUBTOTAL: {totalValue()}</h5>
                      </div>
                      <div class="cart-btns">
                        <a
                          href="#"
                          style={{ width: "100%" }}
                          onClick={() => navigate("/checkout")}
                        >
                          Pagamento <i class="fa fa-arrow-circle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {/*  /Cart */}

                  {/*  Menu Toogle */}
                  <UserProfileAvatar
                    style={{ margingLeft: "auto" }}
                  ></UserProfileAvatar>
                  {/*  /Menu Toogle */}
                </div>
              </div>
              {/*  /ACCOUNT */}
            </div>
            {/*  row */}
          </div>
          {/*  container */}
        </div>
        {/*  /MAIN HEADER */}
      </header>
      {/*  /HEADER */}

      {/*  NAVIGATION */}
      <nav id="navigation">
        {/*  container */}
        <div class="container">
          {/*  responsive-nav */}
          <div id="responsive-nav">
            {/*  NAV */}
            <ul class="main-nav nav navbar-nav">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">Limpeza</a>
              </li>
              <li>
                <a href="#">Higiene</a>
              </li>
              <li>
                <a href="#">Acessórios</a>
              </li>
              <li>
                <a href="#">Outros</a>
              </li>
            </ul>
            {/*  /NAV */}
          </div>
          {/*  /responsive-nav */}
        </div>
        {/*  /container */}
      </nav>
      {/*  /NAVIGATION */}

      {/*  SECTION */}
      <div class="section">
        {/*  container */}
        <div class="container">
          {/*  row */}
          <div class="row">
            {/*  shop */}
            <div class="col-md-4 col-xs-6">
              <div class="shop">
                <div class="shop-img">
                  <img src="./img/shop01.png" alt="" />
                </div>
                <div class="shop-body">
                  <h3>
                    Produtos
                    <br />
                    de Limpeza
                  </h3>
                  <a href="#" class="cta-btn">
                    Ver <i class="fa fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>
            {/*  /shop */}

            {/*  shop */}
            <div class="col-md-4 col-xs-6">
              <div class="shop">
                <div class="shop-img">
                  <img src="./img/shop03.png" alt="" />
                </div>
                <div class="shop-body">
                  <h3>
                    Produtos
                    <br /> de Higiene
                  </h3>
                  <a href="#" class="cta-btn">
                    Ver <i class="fa fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>
            {/*  /shop */}

            {/*  shop */}
            <div class="col-md-4 col-xs-6">
              <div class="shop">
                <div class="shop-img">
                  <img src="./img/shop02.png" alt="" />
                </div>
                <div class="shop-body">
                  <h3>
                    Áreas
                    <br />
                    comuns
                  </h3>
                  <a href="#" class="cta-btn">
                    Ver <i class="fa fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>
            {/*  /shop */}
          </div>
          {/*  /row */}
        </div>
        {/*  /container */}
      </div>
      {/*  /SECTION */}

      {/*  SECTION */}
      <div class="section">
        {/*  container */}
        <div class="container">
          {/*  row */}
          <div class="row">
            {/*  section title */}
            <div class="col-md-12">
              <div class="section-title">
                <h3 class="title">Nossos Produtos</h3>
                <div class="section-nav">
                  <ul class="section-tab-nav tab-nav">
                    <li class="active">
                      <a data-toggle="tab" href="#tab1">
                        Limpeza
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#tab1">
                        Higiene
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#tab1">
                        Acessórios
                      </a>
                    </li>
                    <li>
                      <a data-toggle="tab" href="#tab1">
                        Outros
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  /section title */}

            {/*  Products tab & slick */}
            <div class="col-md-12">
              <div class="row">
                <div class="products-tabs">
                  {/*  tab */}
                  <div id="tab1" class="tab-pane active">
                    <div class="products-slick" data-nav="#slick-nav-1">
                      {/*  product */}

                      {productList &&
                        productList.map((product) => (
                          <div class="product">
                            <div class="product-img">
                              <img src={product.image} alt="" />
                              <div class="product-label">
                                <span class="new">RECORRENTE</span>
                              </div>
                            </div>
                            <div class="product-body">
                              {/* <p class="product-category">Category</p> */}
                              <h3 class="product-name">
                                <a href="#">{product.name}</a>
                              </h3>
                              <h4 class="product-price">
                                <CurrencyFormat
                                  value={product?.price / 100}
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$ "
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </h4>
                              <div class="product-rating">
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                              </div>
                            </div>
                            <div class="add-to-cart">
                              <CartItem
                                onChange={() => setListen(listen + 1)}
                                key={listen}
                                productToSave={product}
                                name={product.name}
                              />
                            </div>
                          </div>
                        ))}

                      {/*  /product */}
                    </div>
                    <div id="slick-nav-1" class="products-slick-nav"></div>
                  </div>
                  {/*  /tab */}
                </div>
              </div>
            </div>
            {/*  Products tab & slick */}
          </div>
          {/*  /row */}
        </div>
        {/*  /container */}
      </div>
      {/*  /SECTION */}

      {/*  HOT DEAL SECTION */}
      <div id="hot-deal" class="section">
        {/*  container */}
        <div class="container">
          {/*  row */}
          <div class="row">
            <div class="col-md-12">
              <div class="hot-deal">
                <ul class="hot-deal-countdown">
                  <li>
                    <div>
                      <h3>02</h3>
                      <span>Dias</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h3>10</h3>
                      <span>Horas</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h3>34</h3>
                      <span>Minutos</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h3>60</h3>
                      <span>secundos</span>
                    </div>
                  </li>
                </ul>
                <h2 class="text-uppercase">Produtos ype</h2>
                <p>Todos os produtos com 30% de desocnto</p>
                <a class="primary-btn cta-btn" href="#">
                  Ver
                </a>
              </div>
            </div>
          </div>
          {/*  /row */}
        </div>
        {/*  /container */}
      </div>
      {/*  /HOT DEAL SECTION */}

      {/*  SECTION */}
      <div class="section">
        {/*  container */}
        <div class="container">
          {/*  row */}
          <div class="row">
            {/*  Products tab & slick */}
            <div class="col-md-12">
              <div class="row">
                <div class="products-tabs">
                  <h3 class="title">ITENS MAIS VENDIDOS</h3>
                  {/*  tab */}
                  <div id="tab1" class="tab-pane active">
                    <div class="products-slick" data-nav="#slick-nav-1">
                      {/*  product */}

                      {productList &&
                        productList.map((product) => (
                          <div class="product">
                            <div class="product-img">
                              <img src={product.image} alt="" />
                              <div class="product-label">
                                <span class="new">RECORRENTE</span>
                              </div>
                            </div>
                            <div class="product-body">
                              {/* <p class="product-category">Category</p> */}
                              <h3 class="product-name">
                                <a href="#">{product.name}</a>
                              </h3>
                              <h4 class="product-price">
                                <CurrencyFormat
                                  value={product?.price / 100}
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$ "
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </h4>
                              <div class="product-rating">
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                              </div>
                            </div>
                            <div class="add-to-cart">
                              <CartItem
                                onChange={() => setListen(listen + 1)}
                                key={listen}
                                productToSave={product}
                                name={product.name}
                              />
                            </div>
                          </div>
                        ))}

                      {/*  /product */}
                    </div>
                    <div id="slick-nav-1" class="products-slick-nav"></div>
                  </div>
                  {/*  /tab */}
                </div>
              </div>
            </div>
            {/*  Products tab & slick */}

            {/*  Products tab & slick */}
            <div class="col-md-12">
              <div class="row">
                <div class="products-tabs">
                  {/*  tab */}
                  <div id="tab1" class="tab-pane active">
                    <div class="products-slick" data-nav="#slick-nav-1">
                      {/*  product */}

                      {productList &&
                        productList.map((product) => (
                          <div class="product">
                            <div class="product-img">
                              <img src={product.image} alt="" />
                              <div class="product-label">
                                <span class="new">RECORRENTE</span>
                              </div>
                            </div>
                            <div class="product-body">
                              {/* <p class="product-category">Category</p> */}
                              <h3 class="product-name">
                                <a href="#">{product.name}</a>
                              </h3>
                              <h4 class="product-price">
                                <CurrencyFormat
                                  value={product?.price / 100}
                                  displayType="text"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$ "
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </h4>
                              <div class="product-rating">
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                              </div>
                            </div>
                            <div class="add-to-cart">
                              <CartItem
                                onChange={() => setListen(listen + 1)}
                                key={listen}
                                productToSave={product}
                                name={product.name}
                              />
                            </div>
                          </div>
                        ))}

                      {/*  /product */}
                    </div>
                    <div id="slick-nav-1" class="products-slick-nav"></div>
                  </div>
                  {/*  /tab */}
                </div>
              </div>
            </div>
            {/*  Products tab & slick */}
          </div>
          {/*  /row */}
        </div>
        {/*  /container */}
      </div>
      {/*  /SECTION */}

      {/*  NEWSLETTER */}
      <div id="newsletter" class="section">
        {/*  container */}
        <div class="container">
          {/*  row */}
          <div class="row">
            <div class="col-md-12">
              <div class="newsletter">
                <p>
                  Receba nossas <strong>PROMOÇÕES</strong>
                </p>
                <form>
                  <input
                    class="input"
                    type="email"
                    placeholder="insira seu email"
                  />
                  <button class="newsletter-btn">
                    <i class="fa fa-envelope"></i> Enviar
                  </button>
                </form>
                <ul class="newsletter-follow">
                  <li>
                    <a href="#">
                      <i class="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-pinterest"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*  /row */}
        </div>
        {/*  /container */}
      </div>
      {/*  /NEWSLETTER */}

      {/*  FOOTER */}
      <footer id="footer">
        {/*  top footer */}
        <div class="section">
          {/*  container */}
          <div class="container">
            {/*  row */}
            <div class="row">
              <div class="col-md-3 col-xs-6">
                <div class="footer">
                  <h3 class="footer-title">Sobre nós</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut.
                  </p>
                  <ul class="footer-links">
                    <li>
                      <a href="#">
                        <i class="fa fa-map-marker"></i>1734 Stonecoal Road
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-phone"></i>+021-95-51-84
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-envelope-o"></i>email@email.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-3 col-xs-6">
                <div class="footer">
                  <h3 class="footer-title">Catgorias</h3>
                  <ul class="footer-links">
                    <li>
                      <a href="#">Higiene</a>
                    </li>
                    <li>
                      <a href="#">Limpeza</a>
                    </li>
                    <li>
                      <a href="#">Outros</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="clearfix visible-xs"></div>

              <div class="col-md-3 col-xs-6">
                <div class="footer">
                  <h3 class="footer-title">Informações</h3>
                  <ul class="footer-links">
                    <li>
                      <a href="#">Sobre nós</a>
                    </li>
                    <li>
                      <a href="#">Entre em contato</a>
                    </li>
                    <li>
                      <a href="#">Politica de privacidadey</a>
                    </li>
                    <li>
                      <a href="#">Compras e cancelamentos</a>
                    </li>
                    <li>
                      <a href="#">Termos e condições</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-md-3 col-xs-6">
                <div class="footer">
                  <h3 class="footer-title">Serviçoes</h3>
                  <ul class="footer-links">
                    <li>
                      <a href="#">Ver carrinho</a>
                    </li>
                    <li>
                      <a href="#">Meus pedidos</a>
                    </li>
                    <li>
                      <a href="#">Ajuda</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  /row */}
          </div>
          {/*  /container */}
        </div>
        {/*  /top footer */}

        {/*  bottom footer */}
        <div id="bottom-footer" class="section">
          <div class="container">
            {/*  row */}
            <div class="row">
              <div class="col-md-12 text-center">
                <ul class="footer-payments">
                  <li>
                    <a href="#">
                      <i class="fa fa-cc-visa"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-credit-card"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-cc-paypal"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-cc-mastercard"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-cc-discover"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-cc-amex"></i>
                    </a>
                  </li>
                </ul>
                {/* <span class="copyright">
                
                  Copyright &copy;
                  <script>document.write(new Date().getFullYear());</script> All
                  rights reserved | This template is made with{" "}
                  <i class="fa fa-heart-o" aria-hidden="true"></i> by{" "}
                  <a href="https://colorlib.com" target="_blank">
                    Colorlib
                  </a>
               
                </span> */}
              </div>
            </div>
            {/*  /row */}
          </div>
          {/*  /container */}
        </div>
        {/*  /bottom footer */}
      </footer>
    </>
  );
};

export default SearchProducts;
